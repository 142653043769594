import { useEffect, useState } from 'react';
import Sidebar from 'src/components/Sidebar/Sidebar';
import Header from 'src/components/Header/Header';
import AppContent from 'src/components/AppContent/AppContent';
import Footer from '../components/Footer';
import defineRulesFor from '../casl/permissions';
import { getUserInfo } from '../utils/auth';

const DefaultLayout = ({ ability }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    try {
      const userInfo = getUserInfo();
      if (!userInfo || !userInfo?.type) {
        localStorage.clear();
        window.location = '/login';
        return;
      }

      const rules = defineRulesFor(userInfo.type);
      ability.update(rules);
    } catch (e) {
      localStorage.clear();
      window.location = '/login';
    }
  }, []);

  return (
    <div className="flex flex-row flex-auto">
      <Sidebar open={open} setOpen={setOpen} />
      <div className="flex-1 flex flex-col w-0">
        <div className="flex-1 flex flex-col">
          <Header open={open} setOpen={setOpen} />
          <AppContent />
        </div>

        <div className="flex-1 flex flex-col justify-end items-center pb-8">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
