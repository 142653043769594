import { gql } from '@apollo/client';
import { MEDIA_FRAGMENT } from './fragment';

export const EVENT_QUERY = {
  GET_ALL_EVENT: gql`
    query listEventCMS($input: ListEventCMSInput!) {
      listEventCMS(input: $input) {
        totalItem
        pagination {
          currentPage
          totalPage
          pageSize
        }
        data {
          _id
          createdAt
          isFeatured
          price
          priceDay
          priceMonth
          priceLimited
          decidedBy {
            userName
            fullName
            type
          }
          series {
            _id
            event {
              page {
                _id
              }
            }
          }
          seriesApproved {
            _id
          }
          page {
            _id
            displayName
            fullName
            listManager {
              userName
              type
            }
          }
          poster {
            url
          }
          name
          type
          status
          bannerImage {
            url
          }
          startTime
          category {
            name
          }
          subCategory {
            name
          }
          artists {
            page {
              fullName
              displayName
            }
            artists {
              name
            }
            type
          }
        }
      }
    }
  `,
  GET_DETAIL_EVENT: gql`
    query getEventInfo($input: GetPublicEventInputDto!) {
      getEventInfo(input: $input) {
        _id
        deletedBy
        decidedBy {
          userName
          fullName
          type
        }
        name
        authorizationLetter {
          ...MediaFragment
        }
        series {
          _id
          event {
            _id
            page {
              _id
            }
          }
          video {
            ...MediaFragment
          }
        }
        page {
          _id
          displayName
          fullName
        }
        description
        startTime
        venue
        slug
        band
        updatedBy
        updatedAt
        streamUrl
        soldTicket
        streamKey
        director
        poster {
          ...MediaFragment
        }
        trailer {
          ...MediaFragment
        }
        isEarlyBird
        earlyBirdPrice
        earlyBirdPriceDay
        status
        earlyBirdPriceMonth
        expiredEarlyBird
        countEarlyBird
        type
        paymentType
        price
        priceDay
        priceMonth
        expiredTime
        priceLimited
        isGenerateTicket
        isBeingLive
        bannerImage {
          ...MediaFragment
        }
        bannerVideo {
          ...MediaFragment
        }
        category {
          _id
          name
        }
        subCategory {
          _id
          name
        }
        status
        artists {
          artists {
            _id
            name
            avatar {
              url
            }
          }
          page {
            avatarId {
              url
            }
            displayName
            _id
          }
          type
        }
        startTime
        expiredTime
        numberSeries
        duration
        isBroadcastAfterLive
        series {
          _id
          name
          description
          seriesNumber
          order
          isApproved
          video {
            ...MediaFragment
          }
          thumbnail {
            ...MediaFragment
          }
        }
        note
        censorshipCategory
        censorshipDescription
      }
    }
    ${MEDIA_FRAGMENT}
  `,
  GET_LIST_TICKETS: (limit, page) => {
    return gql`
        query GET_LIST_TICKETS($eventId_eq: String!) {
          listTicket(
                input: {
                    filter: {
                      eventId_eq: $eventId_eq
                    }
                    pagination: { limit: ${limit}, page: ${page} }
                }
            ) {
                totalItem
                data {
                  _id
                  createdBy
                  eventId
                  orderId
                  createdAt
                  active
                  ticketId
                  activatedBy
                  event {
                    _id
                    title
                    status
                    startTime
                    paymentType
                    currency
                    thumbnail {
                      url {
                        original
                      }
                    }
                  }
                  profile {
                    name
                    userId
                  }
                  userActive{
                    name
                  }
                }
            }
      }`;
  }
};

export const EVENT_MUTATION = {
  DELETE_EVENT: gql`
    mutation remove($id: ID!) {
      event {
        remove(input: { id: $id }) {
          success
        }
      }
    }
  `,
  CHANGE_STATUS_EVENT: gql`
    mutation CHANGE_STATUS_EVENT($id: ID!, $status: EventStatus!) {
      event {
        changeStatus(id: $id, status: $status) {
          _id
          status
        }
      }
    }
  `,
  CREATE_EVENT_CREATOR: gql`
    mutation createEvent($data: CreateEventCmsInput!) {
      event {
        createEventCms(input: $data) {
          success
        }
      }
    }
  `,
  UPDATE_EVENT_CREATOR: gql`
    mutation updateEvent($id: ID!, $data: UpdateEvent!) {
      event {
        updateEvent(_id: $id, input: $data) {
          _id
        }
      }
    }
  `,
  UPDATE_FEATURED_EVENT_CREATOR: gql`
    mutation updateFeaturedEvent($id: ID!, $isFeatured: Boolean!) {
      event {
        updateIsFeature(input: { id: $id, isFeatured: $isFeatured }) {
          success
        }
      }
    }
  `
};
