import { Table } from 'antd';
import React from 'react';
import PaginationCommon from '../PaginationCommon';

const CommonTable = ({
  columns,
  dataSource,
  total,
  currentPage,
  pageSize,
  isLoading,
  itemName,
  onChangeTable,
  pagination = true,
  scroll,
  onRow
}) => {
  return (
    <>
      <Table
        locale={{ emptyText: 'Không có dữ liệu' }}
        className="mt-3"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        scroll={scroll}
        onRow={onRow}
      />
      {pagination && (
        <PaginationCommon
          total={total}
          currentPage={currentPage}
          pageSize={pageSize ? pageSize : 10}
          itemName={itemName}
          onChangeCallback={onChangeTable}
        />
      )}
    </>
  );
};

export default CommonTable;
