import React from "react";
import BrandSvg from "./BrandSvg";
import LogoSvg from "./LogoSvg";
const SidebarHeader = (props) => {
  const { open } = props;
  return (
    <div className="inline-block">{open ? <BrandSvg /> : <LogoSvg />}</div>
  );
};

export default SidebarHeader;
