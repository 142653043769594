import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { ANALYTICS_QUERY } from 'src/constants/analytics.graphql';
import { AnalyticsMatchType, OverViewChartLabel, PredefinedRangeType } from 'src/utils/type';

const useAnalyticManager = () => {
  const [selectedAnalytics, setSelectedAnalytics] = useState([
    OverViewChartLabel?.EVENT,
    OverViewChartLabel?.SoledTicket,
    OverViewChartLabel?.Interaction,
    OverViewChartLabel?.Revenue
  ]);
  const [labelSelected, setLabelSelected] = useState('');
  const [input, setInput] = useState({
    predefinedRange: PredefinedRangeType.LAST_7_DAY,
    stringFilter: {
      matchType: AnalyticsMatchType.EXACT,
      value: '/page/'
    }
  });

  const { data: analyticResponse, loading: analyticLoading } = useQuery(
    ANALYTICS_QUERY.ANALYTIC_CHART,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: input
      }
    }
  );

  const { data: viewResponse } = useQuery(ANALYTICS_QUERY.ANALYTIC_VIEW, {
    fetchPolicy: 'no-cache',
    variables: {
      input: input
    }
  });

  const views = useMemo(() => {
    return viewResponse?.analyticViewPages || [];
  }, [viewResponse]);

  const totalViewNow = useMemo(() => {
    return (
      views?.reduce((total, view) => {
        return total + view?.viewNow;
      }, 0) || 0
    );
  }, [views]);

  const totalViewPre = useMemo(() => {
    return (
      views?.reduce((total, view) => {
        return total + view?.viewPre;
      }, 0) || 0
    );
  }, [views]);

  const percentage = useMemo(() => {
    if (totalViewPre === 0 && totalViewNow === 0) return 0;
    if (totalViewPre === 0 && totalViewNow !== 0) return totalViewNow * 100;
    if (totalViewPre > 0 && totalViewPre > totalViewNow)
      return (totalViewPre * 100) / totalViewNow - 100;
    if (totalViewPre === totalViewNow) return 0;
    if (totalViewPre < totalViewNow) return (totalViewNow * 100) / totalViewPre;
  }, [totalViewNow, totalViewPre]);

  console.log('viewResponse', totalViewNow, totalViewPre, percentage);

  const { data: topEventResponse } = useQuery(ANALYTICS_QUERY.ANALYTIC_TOP_EVENT, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {}
    }
  });

  const analyticsData = useMemo(() => {
    return analyticResponse?.getAnalyticsPageCms?.data || [];
  }, [analyticResponse]);

  const topEvents = useMemo(() => {
    return topEventResponse?.getTopEventPage || [];
  }, [topEventResponse]);

  const overview = analyticsData?.analytics;
  const chart = analyticsData?.chart;

  const revenues = useMemo(() => {
    return analyticsData?.revenues || [];
  }, [analyticsData]);

  return {
    input,
    setInput,
    overview,
    chart,
    revenues,
    setSelectedAnalytics,
    selectedAnalytics,
    setLabelSelected,
    labelSelected,
    topEvents,
    percentage,
    totalViewNow,
    analyticLoading
  };
};

export default useAnalyticManager;
