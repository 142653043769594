import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import useAppendPractice from '../../hooks/useAppendPractice';
import PracticeForm from '../form/practiceForm';

const AppendPracticeToEvent = () => {
  const { id } = useParams();
  const {
    formikRef,
    submitForm,
    initialFields,
    handleSubmit,
    handleCancel,
    isLoading,
    eventDetail
  } = useAppendPractice(id);

  return (
    <div>
      <div>
        <div className="mb-2 text-lg font-bold text-uppercase special-font">
          THÊM TẬP VÀO SỰ KIỆN
        </div>

        <PracticeForm
          handleSubmitForm={handleSubmit}
          formikRef={formikRef}
          data={initialFields}
          eventDetail={eventDetail}
        />

        <div className="flex justify-between mt-8 actions">
          <div />

          <div className="flex gap-3 actions">
            <Button className="btn btn-add" onClick={handleCancel}>
              Hủy bỏ
            </Button>
            <Button className="btn btn-add" onClick={submitForm} disabled={isLoading}>
              Hoàn tất
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppendPracticeToEvent;
