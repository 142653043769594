import React from 'react';
import { BsInfo, BsChevronLeft } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const dataDropdown = [
  // {
  //   title: "profile",
  //   icon: <BsInfo />,
  //   to: "profile",
  // },
  {
    title: 'logout',
    icon: <BsChevronLeft />,
    to: '/login'
  }
];
const InfoDropdown = props => {
  const { setIsOpenDropdown } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClickItem = e => {
    if (e === 'logout') {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userInfo');
      navigate('/login');
    }
    // setIsOpenDropdown((pre) => !pre);
  };
  return (
    <div className="bg-white-header w-40 mt-6">
      {dataDropdown.map((cur, index) => {
        const { title, icon, to } = cur;
        return (
          <Link
            key={index}
            to={to}
            onClick={() => onClickItem(cur.title)}
            className="text-black flex items-center cursor-pointer p-2 opacity-70 hover:opacity-100"
          >
            {icon}
            <div className="text-base font-normal ml-1">{t(title)}</div>
          </Link>
        );
      })}
    </div>
  );
};

export default InfoDropdown;
