import { gql } from '@apollo/client';

export const AUTH_MUTATION = {
    REFRESH_TOKEN: gql`
        mutation refresh_token($refreshToken: String!) {
        account {
            refreshToken(input: { refreshToken: $refreshToken }) {
            accessToken
            }
        }
        }
    `
}