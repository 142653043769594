import * as React from "react"
const TrashIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#F44336"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.5 5h15M6.664 5V3.333a1.667 1.667 0 0 1 1.667-1.667h3.333a1.667 1.667 0 0 1 1.667 1.667V5m2.5 0v11.666a1.667 1.667 0 0 1-1.667 1.667H5.831a1.667 1.667 0 0 1-1.667-1.666V5h11.667ZM8.336 9.166v5M11.664 9.166v5"
    />
  </svg>
)
export default TrashIcon
