import { getLabelPredefinedRange } from 'src/utils/common';
import { formatPriceInput } from 'src/utils/string';
import { OverViewChartLabel } from 'src/utils/type';

const Item = ({ label, isGradient, value, compareTo, comparePercent, isCurrency, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`relative z-10 aspect-square h-[170px] w-full cursor-pointer ${
        isGradient ? 'bg-transparent from-[#081DA1] to-[#F628FA]' : ''
      } rounded-[16px] p-px shadow-xl md:bg-gradient-to-r ${
        value === undefined ? 'opacity-50' : ''
      }`}
    >
      <div className="hidden h-full w-full rounded-[16px] bg-white md:block" />
      <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-between p-3 text-center">
        <h3 className="text-lg font-bold">{label}</h3>
        <div className="bg-white  from-[#081DA1] to-[#F628FA] bg-clip-text  text-center text-4xl font-bold text-transparent md:bg-gradient-to-r">
          {formatPriceInput(value)}
          {isCurrency && <span className="text-xs italic text-gray-600">đ</span>}
        </div>
        <p className="text-gray-700">
          So với {compareTo}
          <span className={`ml-1 ${comparePercent < 0 ? 'text-red-400' : 'text-green-400'}`}>
            {comparePercent}%
          </span>
        </p>
      </div>
    </div>
  );
};
const Overview = ({
  data,
  setSelectedAnalytics,
  setLabelSelected,
  selectedAnalytics,
  percentage,
  totalViewNow,
  type
}) => {
  const handleSelect = label => {
    //Check if label is exist in selectedAnalytics
    const isExist = selectedAnalytics?.includes(label);

    //If not exist, add to selectedAnalytics
    if (!isExist) {
      setSelectedAnalytics(pre => [...pre, label]);
    }

    //If exist, remove from selectedAnalytics
    const index = selectedAnalytics.indexOf(label);
    if (index > -1) {
      setSelectedAnalytics(pre => [...pre.slice(0, index), ...pre.slice(index + 1)]);
    }

    //Set labelSelected
    setLabelSelected(label);
  };

  const isGradient = label => {
    return selectedAnalytics?.includes(label);
  };

  const titleEvent = (
    <span>
      Số lượng <br /> Sự kiện
    </span>
  );

  const titleTicket = (
    <span>
      Số lượng <br /> Vé đã bán
    </span>
  );

  return (
    <div className="z-10 grid grid-cols-4 gap-3 pb-2 mb-6 text-gray-700">
      <Item
        label={titleEvent}
        isGradient={isGradient(OverViewChartLabel?.EVENT)}
        value={data?.eventCount}
        compareTo={getLabelPredefinedRange(type)}
        comparePercent={data?.percentEvent}
        onClick={() => handleSelect(OverViewChartLabel?.EVENT)}
      />
      <Item
        label={titleTicket}
        isGradient={isGradient(OverViewChartLabel?.SoledTicket)}
        value={data?.soldTicketCount}
        compareTo={getLabelPredefinedRange(type)}
        comparePercent={data?.percentTicket}
        onClick={() => handleSelect(OverViewChartLabel?.SoledTicket)}
      />
      <Item
        label="Lượt xem"
        isGradient={false}
        value={totalViewNow}
        compareTo={getLabelPredefinedRange(type)}
        comparePercent={percentage}
      />

      <Item
        label="Doanh số"
        isGradient={isGradient(OverViewChartLabel?.Revenue)}
        value={data?.revenue}
        compareTo={getLabelPredefinedRange(type)}
        isCurrency={true}
        comparePercent={data?.percentRevenue}
        onClick={() => handleSelect(OverViewChartLabel?.Revenue)}
      />
    </div>
  );
};

export default Overview;
