import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import CheckboxField from 'src/components/Form/Checkbox/checkboxField';
import DatePickerField from 'src/components/Form/DatePicker/datePicker';
import DropdownField from 'src/components/Form/Dropdown/dropdownField';
import { ErrorMessageText } from 'src/components/Form/Input/styled';
import { getErrorMessage } from 'src/utils/formik';
import { EarlyBirdOption } from 'src/utils/type';

const EarlyBird = ({ eventValues, onlyPreview }) => {
  const formik = useFormikContext();
  const values = formik?.values || {};

  const options = [
    {
      label: 'Áp dụng',
      value: EarlyBirdOption.ENABLED
    },
    {
      label: 'Không áp dụng',
      value: EarlyBirdOption.DISABLED
    }
  ];

  const isEnabledEarlyBird = values?.earlyBird === EarlyBirdOption.ENABLED;

  console.log('eventValues', eventValues);

  return (
    <div className="flex flex-col grid-cols-12 gap-2 md:grid">
      <div className="col-span-4">
        <h3 className="text-xl font-bold">Giá vé Early Bird</h3>
        <p className="text-sm italic font-light">Giá vé ưu đãi dành cho người dùng mua sớm.</p>
      </div>
      <div className="col-span-8">
        <DropdownField name="earlyBird" options={options} />

        {isEnabledEarlyBird && (
          <>
            <p className="mt-4 text-lg font-bold">Tiêu chí áp dụng Early Bird</p>
            <span className="text-sm italic font-light">
              Khi chọn cả 2 tiêu chí, hệ thống sẽ áp dụng theo tiêu chí hoàn thành sớm nhất
            </span>

            <div className="grid grid-cols-2 mt-4">
              <div className="flex items-center label">
                <CheckboxField
                  name="isCountEarlyBird"
                  label="Số lượng vé tối đa"
                  className="text-base"
                />
              </div>
              <div className="option">
                <Field
                  type="number"
                  name="countEarlyBird"
                  className="form-input"
                  disabled={!values?.isCountEarlyBird || onlyPreview}
                />
                <ErrorMessageText>{getErrorMessage('countEarlyBird', formik)}</ErrorMessageText>
              </div>
            </div>

            <div className="grid grid-cols-2 mt-2">
              <div className="flex items-center label">
                <CheckboxField
                  name="isExpiredEarlyBird"
                  label="Thời gian áp dụng tối đa"
                  className="text-base"
                  disabled={onlyPreview}
                />
              </div>
              <div className="option">
                <DatePickerField
                  name="expiredEarlyBird"
                  disabled={!values?.isExpiredEarlyBird || onlyPreview}
                  minDate={dayjs(eventValues?.startTime)}
                  maxDate={eventValues?.expiredTime ? dayjs(eventValues?.expiredTime) : undefined}
                />
                <ErrorMessageText>{getErrorMessage('expiredEarlyBird', formik)}</ErrorMessageText>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EarlyBird;
