import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthRouter from 'src/AuthRouter';
import routes from 'src/routes';
import Loading from '../Loading/Loading';
const AppContent = () => {
  return (
    <div className="p-5 overflow-hidden">
      <Suspense fallback={<Loading />}>
        <Routes>
          {routes.map(
            (route, idx) =>
              route.element && (
                <Route
                  key={route?.path}
                  path={route?.path}
                  exact={true}
                  name={route?.name}
                  element={<AuthRouter roles={route?.roles}>{route.element}</AuthRouter>}
                />
              )
          )}
          <Route path="/" element={<Navigate to="analytics" replace />} />
          {/* <Route path="*" element={<Navigate to="404" replace />} /> */}
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppContent;
