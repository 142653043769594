import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import DatePickerField from 'src/components/Form/DatePicker/datePicker';
import DragDropUploadField from 'src/components/Form/DragDropUpload';
import { ErrorMessageText } from 'src/components/Form/Input/styled';
import TextArea from 'src/components/Form/TextArea';
import FileUploadField from 'src/components/Form/UploadFile';
import Hint from 'src/components/Hint';
import { getTicketTypeLabel } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/formik';
import { getServerImage } from 'src/utils/image';
import { EventPaymentType, OrderItemType } from 'src/utils/type';
import { validateCreatePractice } from 'src/utils/validate';
import EarlyBird from './earlyBird';
import PriceTableItem from './priceTableItem';

const PracticeInfo = ({ data }) => {
  return (
    <div className="flex flex-col grid-cols-12 gap-4 mt-2 md:grid">
      <div className="grid col-span-6">
        <div>
          <label className="mb-1 text-base font-bold">
            {' '}
            Tên tập (<span className="text-red-600">*</span>)
          </label>
          <input type="text" name="name" className="form-input" disabled value={data?.name || ''} />
        </div>
      </div>
      <div className="grid col-span-6">
        <div>
          <label className="mb-1 text-base font-bold"> File video</label>
          <div className="form-input bg-[#E0E0E0]">
            <div className="grid w-full grid-cols-[1fr_20px] items-center text-[#4D4D4D]">
              <span>{data?.video?.name}</span>
              <SuccessIcon className="ml-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PracticeForm = ({
  index,
  total,
  data,
  handleSubmitForm,
  formikRef,
  eventDetail,
  onlyPreview,
  showPreview,
  disabled
}) => {
  const [isShowForm, setIsShowForm] = useState(true);
  // Automatically set form values when toggle form

  useEffect(() => {
    const formik = formikRef?.current;
    if (isShowForm && formik) {
      formik.setValues(data);
    }
  }, [isShowForm, setIsShowForm, data]);

  const { is_price: hasPrice, is_priceDay: hasPriceDay, is_priceMonth: hasPriceMonth } = data;

  const isShowPrice =
    eventDetail?.paymentType !== EventPaymentType.FREE ||
    data?.isEarlyBird ||
    hasPrice ||
    hasPriceDay ||
    hasPriceMonth;

  return (
    <section className="mt-4 create-practice md:mt-8">
      <Formik
        // initialValues={data}
        onSubmit={handleSubmitForm}
        innerRef={formikRef}
        validationSchema={validateCreatePractice}
      >
        {formik => (
          <Form>
            <div className="flex items-center justify-between header">
              <div className="text-lg font-bold text-uppercase special-font">
                {index && 'Tập ' + index}
                {total && '/' + total}
              </div>
            </div>
            <div className="mb-2 block h-[1px] w-full bg-gray-600" />
            {/* <Field
              type="text"
              name="seriesNumber"
              className={`form-input ${disabled && 'bg-gray-300'}`}
            /> */}
            {!isShowForm && <PracticeInfo data={data || null} />}
            {isShowForm && (
              <div className="flex flex-col grid-cols-12 gap-4 mt-2 md:grid">
                <div className="grid col-span-6">
                  <div>
                    <label className="mb-1 text-base font-bold">
                      {' '}
                      Tên tập (<span className="text-red-600">*</span>)
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className={`form-input ${disabled && 'bg-gray-300'}`}
                      disabled={disabled}
                    />
                    <ErrorMessageText>{getErrorMessage('name', formik)}</ErrorMessageText>
                  </div>
                  <div className="mt-2">
                    <label className="mb-1 text-base font-bold">
                      {' '}
                      Mô tả (<span className="text-red-600">*</span>)
                    </label>
                    <TextArea
                      type="text"
                      name="description"
                      className={`form-input ${disabled && 'bg-gray-300'} !h-[120px]`}
                      disabled={disabled}
                    />
                    <ErrorMessageText>{getErrorMessage('description', formik)}</ErrorMessageText>
                  </div>

                  <div className="mt-2">
                    <label className="mb-1 text-base font-bold">Thời gian</label>
                    <DatePickerField name="startTime" disabled={disabled} />
                    <ErrorMessageText>{getErrorMessage('startTime', formik)}</ErrorMessageText>
                  </div>
                </div>
                <div className="col-span-6">
                  <label className="mb-1 text-base font-bold">
                    Ảnh đại diện (<span className="text-red-600">*</span>)
                  </label>
                  <DragDropUploadField
                    name="thumbnail"
                    className="aspect-video"
                    disabled={disabled}
                  />
                  <ErrorMessageText>{getErrorMessage('thumbnail', formik)}</ErrorMessageText>
                </div>
                <div className="col-span-12">
                  <label className="mb-1 text-base font-bold">
                    File video (<span className="text-red-600">*</span>)
                  </label>
                  <FileUploadField name="video" disabled={disabled} />
                  <ErrorMessageText>{getErrorMessage('video', formik)}</ErrorMessageText>
                </div>

                {showPreview && (
                  <div className="flex justify-center col-span-12 h-[500px]">
                    <video className="aspect-auto" controls>
                      <source
                        src={getServerImage(formik?.values?.video?.url)}
                        key={formik?.values?.video?.url}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                )}

                {isShowPrice && (
                  <div className="col-span-12">
                    <EarlyBird eventValues={eventDetail} onlyPreview={disabled} />
                    <div className="flex items-center gap-2 mt-4">
                      <h2 className="mb-2 text-lg font-bold md:mb-0">
                        Giá áp dụng cho từng loại vé
                      </h2>
                      {/*@ts-ignore*/}
                      <Hint message={'Giá áp dụng cho từng loại vé'} position={'right'} />
                    </div>
                    <div className="grid grid-cols-12 gap-4 pb-2 mt-1 mb-2 border-b-2 border-gray-700 header">
                      <p className="col-span-4 text-base font-bold ">Loại vé</p>
                      <p className="col-span-4 text-base font-bold ">Giá chính thức</p>
                    </div>

                    {hasPriceDay ? (
                      <PriceTableItem
                        name="priceDay"
                        label="48 giờ"
                        formik={formik}
                        hint={getTicketTypeLabel(OrderItemType.SILVER)?.desc}
                        onlyPreview={disabled}
                      />
                    ) : null}

                    {hasPriceMonth ? (
                      <PriceTableItem
                        name="priceMonth"
                        label="30 ngày"
                        formik={formik}
                        hint={getTicketTypeLabel(OrderItemType.GOLD)?.desc}
                        onlyPreview={disabled}
                      />
                    ) : null}

                    {hasPrice ? (
                      <PriceTableItem
                        name="price"
                        label="Không giới hạn"
                        formik={formik}
                        hint={getTicketTypeLabel(OrderItemType.DIAMOND)?.desc}
                        onlyPreview={disabled}
                      />
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default PracticeForm;
