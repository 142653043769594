import { b64DecodeUnicode, b64EncodeUnicode } from './base64-utils';

export const saveUserInfo = data => {
  const encodeVal = b64EncodeUnicode(JSON.stringify(data));
  window.localStorage.setItem('userInfo', encodeVal);
};

export const getUserInfo = () => {
  const userInfo = window.localStorage.getItem('userInfo') || '{}';
  return JSON.parse(userInfo);
};

export const saveAuthInfo = data => {
  const encodeVal = b64EncodeUnicode(JSON.stringify(data));
  window.localStorage.setItem('authStored', encodeVal);
};

export const getAuthInfo = () => {
  const authstore = window.localStorage.getItem('authStored') || '{}';
  const authstoreDecode = b64DecodeUnicode(authstore || '');
  const authstoreObj = JSON.parse(authstoreDecode);
  return authstoreObj;
};

export const isLoggedIn = () => {
  try {
    const accessToken = window.localStorage.getItem('accessToken');
    const refreshToken = window.localStorage.getItem('refreshToken');
    if (accessToken && refreshToken) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getCurRole = () => {
  try {
    const userInfoObj = getUserInfo();
    //NOTE: Fake role is "System"
    userInfoObj.role = 'System';
    return userInfoObj?.role;
  } catch (error) {
    //NOTE: Fake role is "System"
    return 'System';
    // return "roleFalse";
  }
};

export const getAccessToken = () => {
  // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI2MmNmOTAzMmYxNGU1NzNiMjBmMjgzZmEiLCJ3aWQiOjEsImlhdCI6MTY1Nzc5NTA1MH0.6biBjxjJK1VNUpGGbP0UWT9a3M0czIqUzDNCT_gyKlU';
  //hard token
  try {
    const authstoreObj = getAuthInfo();
    return authstoreObj?.accesstoken;
  } catch (error) {
    return '';
  }
};

export const getRefreshToken = () => {
  try {
    const authstoreObj = getAuthInfo();
    return authstoreObj?.refreshtoken;
  } catch (error) {
    return '';
  }
};
