import BannerIcon from './components/IconMenu/BannerIcon';
import DashboardIcon from './components/IconMenu/DashboardIcon';
import GroupIconOutlined from './components/IconMenu/GroupIconOutlined';
import LiveshowsIcon from './components/IconMenu/LiveshowsIcon';
import SettingIcon from './components/IconMenu/SettingIcon';

/**
 * Info of menu data
 * @title for display title in Sidebar (required)
 * @icon for Icon display
 * @to for Link to (required)
 */
const menus = [
  {
    id: 1,
    title: 'title-analytic',
    icon: <DashboardIcon />,
    to: 'analytics',
    submenu: true,
    can: ['read', 'analytic'],

    // roles: ["admin"],
    submenuItems: [
      {
        id: 2,
        title: 'title-overview',
        to: 'analytics',
        can: ['read', 'overview']
      },
      {
        id: 3,
        title: 'title-revenue',
        to: 'revenue',
        can: ['read', 'revenue']
      },
      {
        id: 4,
        title: 'title-giao-dich',
        to: 'transactions',
        can: ['read', 'transaction']
      },
      {
        id: 5,
        title: 'title-ke-toan',
        to: 'accountings',
        can: ['read', 'accountant']
      }
    ]
  },
  {
    id: 6,
    title: 'title-manage-account',
    icon: <GroupIconOutlined />,
    to: 'users',
    submenu: true,
    can: ['read', 'user'],
    submenuItems: [
      {
        id: 7,
        title: 'title-user',
        to: 'users/audiences',
        can: ['read', 'user']
      },
      {
        id: 9,
        title: 'title-quan-tri-vien',
        to: 'users/administrators',
        can: ['read', 'admin']
      },
      {
        id: 8,
        title: 'title-danh-sach-cua-toi',
        to: 'users/my-page',
        can: ['read', 'my-page']
      }
    ]
  },
  // {
  //   id: 10,
  //   title: 'title-danh-muc-noi-dung',
  //   icon: <StackIconOutlined />,
  //   to: 'catalogs',
  //   can: ['read', 'category']
  // },
  // {
  //   id: 11,
  //   title: 'title-ke-toan',
  //   icon: <BankOutlined />,
  //   to: 'accountings',
  //   can: ['read', 'accountant']
  // },
  // {
  //   title: "title-kenh-phan-phoi",
  //   icon: <DistributionChannelOutlined />,
  //   to: "distributors",
  // },
  // {
  //   id: 4,
  //   title: 'title-qua-tang',
  //   icon: <GiftIcon />,
  //   to: 'gifts',
  //   submenu: true,
  //   submenuItems: [
  //     { title: 'title-vat-pham', to: 'gifts/items' },
  //     { title: 'title-ty-le-chia-se', to: 'gifts/shared-rate' }
  //   ]
  // },

  {
    id: 12,
    title: 'title-manage-event',
    icon: <LiveshowsIcon />,
    to: 'liveshows',
    can: ['read', 'event']
  },
  // {
  //   title: "title-ticket",
  //   icon: <TicketIcon />,
  //   to: "tickets",
  // },

  {
    id: 13,
    title: 'title-manage-ads',
    icon: <BannerIcon />,
    to: 'sliders',
    submenu: true,
    submenuItems: [
      { title: 'title-sliders', to: 'sliders', can: ['read', 'banner'] },
      { title: 'title-banners', to: 'banners', can: ['read', 'banner'] }
    ],
    can: ['read', 'banner']
  },

  // {
  //   id: 18,
  //   title: 'title-promotion',
  //   icon: <CrownOutlined />,
  //   submenu: true,
  //   to: 'voucher',
  //   can: ['read', 'voucher'],
  //   submenuItems: [
  //     {
  //       id: 19,
  //       title: 'title-voucher',
  //       to: 'voucher',
  //       can: ['read', 'menu']
  //     },
  //     {
  //       id: 20,
  //       title: 'title-combo',
  //       to: 'combo',
  //       can: ['read', 'category']
  //     }
  //   ]
  // },
  {
    id: 14,
    title: 'title-config',
    icon: <SettingIcon />,
    submenu: true,
    to: 'settings',
    can: ['read', 'setting'],
    submenuItems: [
      {
        id: 15,
        title: 'title-category',
        to: 'config/menu',
        can: ['read', 'menu']
      },
      {
        id: 16,
        title: 'title-sub-category',
        to: 'catalogs',
        can: ['read', 'category']
      },
      {
        id: 17,
        title: 'title-setting-locale',
        to: 'language',
        can: ['read', 'language']
      }
    ]
  }
];

const converPathNameForMenu = path => {
  let tempPath = path;
  if (path.includes('/detail')) {
    tempPath = path.slice(0, path.lastIndexOf('/detail'));
  } else if (path.includes('/edit')) {
    tempPath = path.slice(0, path.lastIndexOf('/edit'));
  } else if (path.includes('/add')) {
    tempPath = path.slice(0, path.lastIndexOf('/add'));
  }
  return tempPath;
};

export { converPathNameForMenu, menus };
