import { DatePicker as Picker } from 'antd';
import dayjs from 'dayjs';
import { localeVN } from 'src/pages/dashboard';

function DatePicker(
  props = {
    format: 'HH:mm DD/MM/YYYY'
  }
) {
  const {
    defaultValue,
    format,
    value,
    showTime,
    onChange,
    disabled,
    placeholder,
    minDate,
    maxDate
  } = props;

  console.log('minDate', dayjs(minDate).format('DD/MM/YYYY'));
  const disabledDate = current => {
    if (!minDate) {
      return undefined;
    }
    if (minDate) {
      return current && current < dayjs(minDate);
    }
    // Disable dates before today
    return current && current < dayjs(minDate);
  };

  return (
    // @ts-ignore
    <Picker
      locale={localeVN}
      className={`${disabled ? '!bg-gray-300' : ''}`}
      value={value}
      defaultValue={defaultValue}
      format={format}
      disabledDate={disabledDate}
      onChange={onChange}
      onSelect={onChange}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
}
export default DatePicker;
