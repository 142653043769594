import * as React from 'react';
const CheckedCircleBlue = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 40 40" {...props}>
    <path
      fill="#98ccfd"
      d="M20 38.5C9.799 38.5 1.5 30.201 1.5 20S9.799 1.5 20 1.5 38.5 9.799 38.5 20 30.201 38.5 20 38.5z"
    />
    <path
      fill="#4788c7"
      d="M20 2c9.925 0 18 8.075 18 18s-8.075 18-18 18S2 29.925 2 20 10.075 2 20 2m0-1C9.507 1 1 9.507 1 20s8.507 19 19 19 19-8.507 19-19S30.493 1 20 1z"
    />
    <path
      fill="#fff"
      d="m16.025 28.02-6.907-6.907 2.122-2.121 4.785 4.785 12.093-12.093 2.122 2.121z"
    />
  </svg>
);
export default CheckedCircleBlue;
