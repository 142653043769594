import { AbilityBuilder } from '@casl/ability';
import { AdminType } from '../utils/type';

export default function defineRulesFor(role) {
  const { can, cannot, rules } = AbilityBuilder.extract();

  console.log('role: ', role);

  switch (role) {
    case AdminType.ROOT:
      can('manage', 'all');
      cannot('read', 'my-page');
      break;
    case AdminType.ADMIN:
      can('manage', 'event');
      can('manage', 'user');
      can('manage', 'revenue');

      //   can('manage', 'menu');
      //   can('manage', 'category');
      can('manage', 'banner');
      can('manage', 'setting');
      can('manage', 'my-page');
      can('manage', 'analytic');
      can('manage', 'overview');
      can('manage', 'admin');
      can('manage', 'voucher');
      can('manage', 'combo');
      can('manage', 'language');
      break;
    case AdminType.SUPERADMIN:
      can('manage', 'event');
      can('manage', 'user');
      //   can('manage', 'menu');
      //   can('manage', 'category');
      can('manage', 'banner');
      can('manage', 'setting');
      can('manage', 'my-page');
      can('manage', 'voucher');
      can('manage', 'combo');
      //   can('manage', 'analytic');
      //   can('manage', 'overview');
      can('manage', 'admin');

      can('manage', 'language');
      break;
    case AdminType.ACCOUNTANT:
      can('manage', 'analytic');
      can('manage', 'transaction');
      can('manage', 'accountant');
      //   can('manage', 'overview');
      can('manage', 'revenue');
      break;
    default:
  }

  return rules;
}
