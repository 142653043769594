import { useField, useFormikContext } from 'formik';
import CheckedIcon from 'src/components/Icons/checked';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  .unchecked {
    width: 22px;
    height: 22px;
    display: inline-block;
    border: 1px solid gray;
    border-radius: 4px;
  }

  @media screen and (max-width: 767px) {
    transform: scale(0.9);
  }
`;

const CheckboxField = ({ label, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);

  return (
    <Wrapper
      className={props?.className}
      onClick={() => {
        if (props?.disabled) {
          return;
        }

        if (typeof props?.onChange === 'function') {
          props?.onChange(!field.value);
        }
        setFieldTouched(field.name, true);
        setFieldValue(field.name, !field.value);
      }}
    >
      {field?.value ? <CheckedIcon width={22} height={22} /> : <div className="unchecked" />}
      <label htmlFor={field.name}>{label}</label>
    </Wrapper>
  );
};

export default CheckboxField;
