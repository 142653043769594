import { Dropdown } from 'antd';
import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import InfoDropdown from './InfoDropdown';
import UserInfo from './UserInfo';

const UserContent = () => {
  const [isOpenDrowpdown, setIsOpenDropdown] = useState(false);
  const onChangeDropdown = e => {
    setIsOpenDropdown(e);
  };
  return (
    <div className="relative flex items-center justify-between gap-5 px-5 py-2 text-white border-2 rounded-2xl bg-dark-purple">
      <UserInfo />
      <div>
        <Dropdown
          trigger={['click']}
          placement="bottomRight"
          open={isOpenDrowpdown}
          onOpenChange={onChangeDropdown}
          overlay={<InfoDropdown setIsOpenDropdown={setIsOpenDropdown} />}
        >
          <BsChevronDown
            className={`cursor-pointer duration-150 ${isOpenDrowpdown && 'rotate-180'}`}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default UserContent;
