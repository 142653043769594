export const convertToISODate = date => {
  if (!date) {
    return undefined;
  }
  return new Date(date).toISOString();
};

export const convertHourMinuteToSecond = (hour, minute) => {
  if (hour || minute) {
    const totalMinutes = (hour || 0) * 60 + (minute || 0);
    return totalMinutes * 60;
  } // To seconds
  return undefined;
};

export const toHoursAndMinutes = totalSeconds => {
  const SECOND_PER_HOUR = 60 * 60; // 3600;
  const SECOND_PER_MINUTE = 60;
  const hours = Math.floor(totalSeconds / SECOND_PER_HOUR);
  const remainSeconds = totalSeconds - hours * SECOND_PER_HOUR;
  const minutes = Math.floor(remainSeconds / SECOND_PER_MINUTE);
  return {
    hours,
    minutes
  };
};
