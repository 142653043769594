import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Observable, getMainDefinition } from '@apollo/client/utilities';
import { Modal } from 'antd';
import { GraphQLError } from 'graphql';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import App from './App';
import useLogOut from './components/hooks/logOut';
import { AUTH_MUTATION } from './constants/auth';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
});

const wsLink = new WebSocketLink(
  new SubscriptionClient(
    process.env.REACT_APP_BASE_SOCKET || 'wss://api-metahall-2.mefibay.com/graphql',
    {
      reconnect: true
    }
  )
);

// const wsLink = new GraphQLWsLink(
//   createClient({
//     url: process.env.REACT_APP_BASE_SOCKET,
//     connectionParams: {
//       authToken: user.authToken
//     }
//   })
// );

function isRefreshRequest(operation) {
  return operation.operationName === 'refreshToken';
}

// Returns access token if operation is not a refresh token request
function returnTokenDependingOnOperation(operation) {
  if (isRefreshRequest(operation)) return localStorage.getItem('refreshToken');
  return localStorage.getItem('accessToken') || '';
}

// Request a refresh token to then stores and returns the accessToken.
const refreshToken = async () => {
  try {
    const refreshTokenValue = localStorage.getItem('refreshToken');
    const refreshResolverResponse = await client.mutate({
      mutation: AUTH_MUTATION.REFRESH_TOKEN,
      variables: { refreshToken: refreshTokenValue }
    });
    const accessToken = refreshResolverResponse.data?.account?.refreshToken?.accessToken;
    localStorage.setItem('accessToken', accessToken || '');
    return accessToken;
  } catch (errRf) {
    localStorage.clear();
    throw errRf;
  }
};

const authLink = setContext((operation, { headers }) => {
  const token = returnTokenDependingOnOperation(operation);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const error = () => {
  Modal.error({
    title: 'Lỗi',
    content: 'Sai tài khoản hoặc mật khẩu'
  });
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const errGr of graphQLErrors) {
      if (errGr.message === '2 UNKNOWN: Account not exist') {
        error();
      }
      // expired token
      if (
        (errGr.extensions.code === 'UNAUTHENTICATED' ||
          errGr.extensions?.exception?.message === 'jwt expired' ||
          errGr.extensions?.exception?.name === 'TokenExpiredError') &&
        operation.operationName !== 'refreshToken'
      ) {
        if (operation.operationName === 'refreshToken') return;
        const observable = new Observable(observer => {
          (async () => {
            try {
              const accessToken = await refreshToken();
              if (!accessToken) {
                useLogOut();
                throw new GraphQLError('Hết hạn đăng nhập, yêu cầu đăng nhập lại.');
              }
              // Retry the failed request
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              };
              forward(operation).subscribe(subscriber);
            } catch (err) {
              observer.error(err);
            }
          })();
        });
        return observable;
      }
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
};
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  authLink.concat(wsLink),
  authLink.concat(httpLink)
);

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  // link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions
});
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
