import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronDown } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { Can } from '../../casl/Abilities';

const SidebarItem = props => {
  const { open, item, layer, curMenuActive } = props;
  const [isOpenSub, setIsOpenSub] = useState(false);
  const { t } = useTranslation();
  // const history = useHistory();
  const navigate = useNavigate();

  //NOTE change currentRole by get local currentRole
  const currentRole = 'user';

  if (item.roles && item.roles.indexOf(currentRole) === -1) {
    return null;
  }

  if (item.submenu) {
    return (
      <>
        <div
          className={`text-gray-50 text-sm flex font-thin items-center gap-x-4 cursor-pointer py-2 hover:opacity-100 rounded-md ${
            item?.spacing ? 'mt-9' : 'mt-2'
          } ${!isOpenSub && 'opacity-70'} ${layer === 1 && 'px-2'} ${
            layer === 2 && 'px-12 opacity-80'
          } ${layer === 3 && 'px-16 opacity-70'}`}
          onClick={() => {
            if (item.submenuItems[0].to) {
              navigate(item.submenuItems[0].to, {
                replace: true
              });
            }
            setIsOpenSub(!isOpenSub);
          }}
        >
          {item.icon && <span className="block float-left text-2xl">{item.icon}</span>}
          <span
            className={`text-base uppercase font-bold flex-1 duration-200 ${!open && 'hidden'} `}
          >
            {t(item.title)}
          </span>
          <BsChevronDown className={`${isOpenSub && 'rotate-180 '} duration-300`} />
        </div>
        {isOpenSub && open && (
          <div>
            {item.submenuItems.map((subItem, index) => (
              <Can I={subItem?.can?.[0] || 'read'} a={subItem?.can?.[1] || 'all'} key={index}>
                <SidebarItem
                  open={open}
                  item={subItem}
                  layer={layer + 1}
                  curMenuActive={curMenuActive}
                />
              </Can>
            ))}
          </div>
        )}
      </>
    );
  } else {
    return (
      <Link
        to={!item.submenu && item.to}
        className={`text-gray-50 opacity-80 text-sm font-thin flex items-center gap-x-4 cursor-pointer py-2 hover:opacity-100 hover:text-gray-50 rounded-md ${
          curMenuActive?.includes(`/${item.to}`) && 'active-menu'
        } ${item?.spacing ? 'mt-9' : 'mt-2'} ${layer === 1 && 'px-2 '} ${
          layer === 2 && 'px-12 opacity-80'
        } ${layer === 3 && 'px-16 opacity-70'} `}
      >
        {item.icon && <span className="block float-left text-2xl">{item.icon}</span>}

        <span className={`text-base font-bold uppercase flex-1 duration-200 ${!open && 'hidden'} `}>
          {t(item.title)}
        </span>
      </Link>
    );
  }
};

export default SidebarItem;
