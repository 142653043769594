// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  box-shadow: rgb(223 223 223) 0 0 3px 3px inset;
  background-color: #fff;

  ${({ disabled }) => disabled && `pointer-events: none; background: #e0e0e0;`};

  p {
    margin: 0;
  }

  .selectedOption {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1rem;
  }

  @media screen and (max-width: 767px) {
    .selectedOption {
      padding: 0 10px;
    }
  }
`;

export const ArrowIcon = styled.span`
  border: solid #606060;
  border-width: 0 2.5px 2.5px 0;
  padding: 2.5px;
  display: block;
  ${({ isOpen }) =>
    isOpen
      ? `
    transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  `
      : `
  
    transform: rotate(45deg);
     -webkit-transform: rotate(45deg);
 
    
  `}
`;

export const OptionList = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-top: 5px;
  position: absolute;
  z-index: 999;
  top: 40px;
  width: 100%;
`;

export const OptionItemBase = styled.div`
  background: white;
  color: #000;

  ${({ selected }) =>
    selected &&
    `
    color: white;
    background: #8a8a8a;
  `}
  :hover {
    color: white;
    background: #8a8a8a;
  }

  min-height: 40px;
`;

export const OptionItem = styled(OptionItemBase)`
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
  position: relative;
  :hover {
    > div {
      display: block;
    }
  }
`;

export const ChildDropDownWrapper = styled.div`
  position: absolute;
  left: 100%;
  width: 150px;
  top: 0;
  align-items: center;
  display: none;
`;

export const ChildOptionItem = styled(OptionItemBase)`
  padding: 10px 20px;
`;
