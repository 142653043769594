import { gql } from '@apollo/client';

export const ADMIN_MUTATION = {
  CREATE_ADMIN: gql`
    mutation createAdmin($input: AdminInputDto!) {
      createAdmin(input: $input) {
        success
      }
    }
  `,
  LOCK_UNLOCK_ADMIN: gql`
    mutation activeOrDeactiveAdmin($input: ActiveOrDeactiveAdminInput!) {
      activeOrDeactiveAdmin(input: $input) {
        success
      }
    }
  `,
  ADD_PAGE_FOR_ADMIN: gql`
    mutation addPageForAdmin($input: AddPageForAdminInput!) {
      addPageForAdmin(input: $input) {
        success
      }
    }
  `,
  DELETE_ADMIN: gql`
    mutation deleteAdmin($input: DeleteAdminInput!) {
      deleteAdmin(input: $input) {
        success
      }
    }
  `
};

export const ADMIN_QUERY = {
  LIST_ADMIN: gql`
    query listAdmin($input: ListAdminInput!) {
      listAdmin(input: $input) {
        totalItem
        data {
          _id
          userName
          createdAt
          fullName
          active
          type
          pageIds {
            _id
            displayName
          }
        }
      }
    }
  `,
  LIST_PAGE_OF_ADMIN: gql`
    query detailPagesOfAdmin($input: DetailPagesOfAdminInput!) {
      detailPagesOfAdmin(input: $input) {
        data {
          _id
          createdAt
          createdBy
          fullName
          phoneNumber
          email
          verified
          statusVerify
          pagesOfAdmin {
            _id
            fullName
            displayName
            status
            type
            statusVerified
            countEventLive
            countEventBroadcast
          }
        }
      }
    }
  `
};
