import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const initUserInfo = {
  type: null,
  userName: null,
  avatarLink: null
};
const mappingRole = {
  ROOT: 'Quản lý',
  SUPERADMIN: 'Kiểm duyệt viên',
  ADMIN: 'Phụ trách khách hàng',
  ACCOUNTANT: 'Ké toán'
};
const UserInfo = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState({ ...initUserInfo });

  useEffect(() => {
    const userInfoObj = JSON.parse(localStorage.getItem('userInfo'));
    setUserInfo(userInfoObj);
  }, []);

  return (
    <div className="flex items-center justify-center ">
      {/* <img className="w-12 h-12 mr-2 rounded-full" src={userInfo?.avatarLink || Avatar} alt="avt" /> */}
      <div className="items-start justify-center hidden gap-1 md:flex">
        <div className="text-base font-semibold">{userInfo?.userName || 'User name'}</div>
        <div style={{ color: '#777E90' }} className="text-base font-normal uppercase">
          ({userInfo?.type ? t(mappingRole[userInfo.type]) : t('admin')})
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
