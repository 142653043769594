import { gql } from '@apollo/client';

export const MUTATION_QUERY = {
  LOGIN: gql`
    mutation adminLogin($input: AdminInputDto!) {
      adminLogin(input: $input) {
        token
        refreshToken
        expiresAt
        refreshTokenExpiresAt
        payload {
          _id
          userName
          type
        }
      }
    }
  `,

  DELETE_CATAGORY: gql`
    mutation DeleteCategory($id: ID!) {
      category {
        delete(input: { id: $id }) {
          success
        }
      }
    }
  `,

  CREATE_USER: gql`
    mutation CreateUser(
      $name: String!
      $phoneNumber: String!
      $prefix: String!
      $password: String!
      $type: UserTypes!
    ) {
      users_CMS {
        create(
          input: {
            name: $name
            phoneNumber: $phoneNumber
            prefix: $prefix
            password: $password
            type: $type
          }
        ) {
          success
        }
      }
    }
  `,
  UPDATE_CREATOR: gql`
    mutation updateCreatorProfile($id: ID!, $data: UpdateCreatorProfileUpdateInput!) {
      profile {
        updateCreatorProfile(input: { id: $id, data: $data }) {
          success
        }
      }
    }
  `,

  SET_PASSWORD: gql`
    mutation SET_PASSWORD($id: ID!, $password: String!) {
      users_CMS {
        setPassword(input: { id: $id, password: $password }) {
          success
        }
      }
    }
  `,

  DELETE_USER: gql`
    mutation deleteUser($id: String!) {
      user {
        deleteUser(input: { id: $id }) {
          success
        }
      }
    }
  `,

  AUTHEN_PRODUCER: gql`
    mutation authenProducer($id: ID!, $isFeatured: Boolean!) {
      profile {
        updateFeaturedCreator(input: { id: $id, isFeatured: $isFeatured }) {
          success
        }
      }
    }
  `,

  ACTIVE_USER: gql`
    mutation ActiveUser($id: ID!, $action: UserActiveStatus!) {
      users_CMS {
        active(input: { id: $id, action: $action }) {
          success
        }
      }
    }
  `,
  VERIFY_CREATOR: gql`
    mutation VERIFY_CREATOR($id: ID!) {
      users_CMS {
        verifyCreator(input: { id: $id }) {
          success
        }
      }
    }
  `,
  CREATE_CATEGORY: gql`
    mutation CreateCategory(
      $name: String!
      $backgrounds: [ID!]
      $parentID: ID
      $colors: [String!]
    ) {
      categories {
        create(
          input: { name: $name, backgrounds: $backgrounds, parentID: $parentID, colors: $colors }
        ) {
          _id
          backgrounds {
            url
          }
        }
      }
    }
  `,

  UPDATE_CATEGORY: gql`
    mutation UpdateCategory(
      $id: ID!
      $name: String!
      $backgrounds: [ID!]
      $position: Float
      $parentCategory: ID
      $colors: [String!]
    ) {
      category {
        update(
          input: {
            data: {
              name: $name
              backgrounds: $backgrounds
              position: $position
              parentCategory: $parentCategory
              colors: $colors
            }
            id: $id
          }
        ) {
          success
        }
      }
    }
  `,

  DELETE_BANNER: gql`
    mutation DeleteBanner($id: ID!) {
      banner {
        delete(input: { id: $id }) {
          success
        }
      }
    }
  `,

  CREATE_BANNER: gql`
    mutation createBanner(
      $category: ID
      $status: Boolean
      $image: String!
      $en: String!
      $vi: String!
      $price: String!
      $name: String!
      $title: String!
      $url: String!
      $miniTitle: String!
      $buttonText: String!
      $promo: String!
      $time: Float!
      $position: Float!
    ) {
      banner {
        create(
          input: {
            name: $name
            title: $title
            miniTitle: $miniTitle
            buttonText: $buttonText
            promo: $promo
            #   event:"62eb661301e6f08456cbbdc5"
            time: $time
            location: { en: $en, vi: $vi }
            category: $category # id category danh mục lớn
            status: $status
            price: $price
            url: $url
            image: $image #phair upload anh tra ve ID va get ID vao DAY
            position: $position
          }
        ) {
          createdAt
          createdBy
          updatedAt
          updatedBy
          _id
          name
          miniTitle
          title
          buttonText
          status
          time
          location {
            vi
            en
          }
          price
          category {
            _id
            name
            slug
          }
          url
          image {
            _id
            url {
              original
            }
          }
          position
        }
      }
    }
  `,

  DELETE_MENU: gql`
    mutation DeleteMenu($id: ID!) {
      menu {
        delete(input: { id: $id }) {
          success
        }
      }
    }
  `,
  CREATE_MENU: gql`
    mutation createMenu(
      $name: String!
      $url: String!
      $category: ID
      $status: Boolean!
      $position: Float!
      $type: MenuTypes!
      $isRoot: Boolean!
      $parentMenu: ID
    ) {
      menu {
        create(
          input: {
            name: $name
            position: $position
            isRoot: $isRoot
            parentMenu: $parentMenu
            status: $status
            category: $category
            url: $url
            type: $type
          }
        ) {
          _id
          name
          position
        }
      }
    }
  `,
  UPDATE_BANNER_POSITION: gql`
    mutation updateBannerPosition($input: [MoveBannerParam!]!) {
      banner {
        move(input: $input) {
          success
        }
      }
    }
  `,
  UPDATE_BANNER: gql`
    mutation updateBanner(
      $id: ID!
      $category: ID
      $status: Boolean
      $image: String!
      $en: String!
      $vi: String!
      $price: String!
      $url: String!
      $name: String!
      $title: String!
      $miniTitle: String!
      $buttonText: String!
      $promo: String!
      $time: Float!
      $position: Float!
    ) {
      banner {
        update(
          input: {
            id: $id
            input: {
              name: $name
              title: $title
              miniTitle: $miniTitle
              buttonText: $buttonText
              promo: $promo
              #   event:"62eb661301e6f08456cbbdc5"
              time: $time
              location: { en: $en, vi: $vi }
              category: $category # id category danh mục lớn
              status: $status
              price: $price
              url: $url
              image: $image #phair upload anh tra ve ID va get ID vao DAY
              position: $position
            }
          }
        ) {
          createdAt
          createdBy
          updatedAt
          updatedBy
          _id
          name
          miniTitle
          title
          buttonText
          status
          time
          location {
            vi
            en
          }
          price
          category {
            _id
            name
            slug
          }
          url
          image {
            _id
            url {
              original
            }
          }
          position
        }
      }
    }
  `,

  UPDATE_STATUS_BANNER: gql`
    mutation updateBanner($id: ID!, $status: Boolean) {
      banner {
        update(input: { id: $id, input: { status: $status } }) {
          createdAt
          createdBy
          updatedAt
          updatedBy
          _id
          name
          miniTitle
          title
          buttonText
          status
          time
          location {
            vi
            en
          }
          price
          category {
            _id
            name
            slug
          }
          url
          image {
            _id
            url {
              original
            }
          }
          position
        }
      }
    }
  `,
  UPDATE_MENU_POSITION: gql`
    mutation updateMenuPosition($input: [MoveMenuParam!]!) {
      menu {
        move(input: $input) {
          success
        }
      }
    }
  `,
  UPDATE_CHILD_MENU_POSITION: gql`
    mutation updateMenuPosition($input: MoveChildInput!) {
      menu {
        moveChild(input: $input) {
          success
        }
      }
    }
  `,
  UPDATE_MENU: gql`
    mutation updateMenu($id: ID!, $input: UpdateMenuField!) {
      menu {
        update(input: { id: $id, input: $input }) {
          _id
          name
          status
        }
      }
    }
  `
};

// $serviceType: AuthServiceEnum!
// serviceType: $serviceType
// filter: { query: $query, isActive: $isActive }
export const QUERY = {
  GET_LIST_CATEGORY_EVENT: gql`
    query {
      listCategories(input: { pagination: { limit: 100, page: 1 } }) {
        totalItem
        data {
          _id
          name
          childCategories {
            _id
            name
            childCategories {
              _id
              name
            }
          }
        }
      }
    }
  `,
  GET_LIST_PAGE_IN_SYSTEM: gql`
    query listPage($input: ListPageInput!) {
      listPage(input: $input) {
        totalItem
        pagination {
          currentPage
          totalPage
          pageSize
        }
        data {
          _id
          fullName
          countEventLive
          countEventBroadcast
          displayName
          avatarId {
            url
          }
          status
          job
          totalFollow
        }
      }
    }
  `,

  GET_LIST_CATAGORY: gql`
    query listCategories($input: ListCategoryInput!) {
      listCategories(input: $input) {
        totalItem
        pagination {
          currentPage
          totalPage
          pageSize
        }
        data {
          _id
          name
          slug
          backgrounds {
            _id
            url
          }
          colors
          parent {
            _id
            name
            slug
            backgrounds {
              _id
              url
            }
          }
          order
          childCategories {
            _id
            name
            slug
            backgrounds {
              _id
              url
            }
            childCategories {
              _id
              name
              slug
              backgrounds {
                _id
                url
              }
            }
          }
        }
      }
    }
  `,
  GET_CATEGORY_DETAIL: gql`
    query GetCategoryDetail($id: ID!) {
      category(input: { id: $id }) {
        _id
        name
        slug
        backgrounds {
          _id
          url
        }
        colors
        childCategories {
          _id
          name
          slug
        }
      }
    }
  `,
  GET_BANNER_LIST: gql`
    query getBannerList(
      $limit: Int!
      $page: Int!
      $category_eq: ID
      $status_eq: Boolean
      $position: SortDirectionEnum
    ) {
      listBannerCMS(
        input: {
          sort: { position: $position }
          filter: { category_eq: $category_eq, status_eq: $status_eq }
          pagination: { limit: $limit, page: $page }
        }
      ) {
        totalItem
        pagination {
          currentPage
          totalPage
          pageSize
        }
        data {
          _id
          name
          miniTitle
          title
          status
          position
          promo
          url
          image {
            url {
              original
            }
          }
          location {
            en
            vi
          }
          time
          price
          buttonText
        }
      }
    }
  `,

  GET_BANNER_DETAIL: gql`
    query getBannerList($id: ID!) {
      banner(input: { id: $id }) {
        _id
        createdAt
        createdBy
        updatedBy
        updatedAt
        deletedBy
        deletedAt
        name
        miniTitle
        title
        buttonText
        status
        promo
        event {
          _id
          title
          url
        }
        time
        location {
          en
          vi
        }
        price
        category {
          name
          slug
          _id
        }
        url
        image {
          _id
          url {
            original
          }
        }
        position
      }
    }
  `,

  GET_MENU_DETAIL: gql`
    query getMenuDetail($id: ID) {
      menuDetail(input: { id: $id }) {
        _id
        name
        status
        position
        isRoot
        url
        type
        category {
          _id
          name
          slug
        }
      }
    }
  `,
  GET_LIST_MENU: gql`
    query getMenuList(
      $position: SortDirectionEnum!
      $type_eq: MenuTypes!
      $limit: Int!
      $page: Int!
      $isRoot_eq: Boolean!
    ) {
      listMenuCMS(
        input: {
          sort: { position: $position }
          filter: {
            # name_contains:""
            type_eq: $type_eq
            isRoot_eq: $isRoot_eq
            # status_eq:true
          }
          pagination: { limit: $limit, page: $page }
        }
      ) {
        totalItem
        pagination {
          currentPage
          totalPage
          pageSize
        }
        data {
          _id
          name
          status
          isRoot
          url
          category {
            _id
            name
          }
          type
          position
          childMenus {
            menu {
              _id
              name
              status
              position
              isRoot
              url
              category {
                _id
                name
              }
              type
            }
            position
          }
          createdAt
          createdBy
          updatedBy
          updatedAt
        }
      }
    }
  `,

  GET_DETAIL_PRODUCER: gql`
    query getDetailProducer($id: ID!) {
      detailCreatorProfile(id: $id) {
        _id
        userId
        name
        nickname
        image {
          _id
          url {
            original
          }
        }
        email
        createdAt
        activeTime
        artists
        fields
        field
        bio
        countEventLiveShow
        countEventBroadcast
        fans {
          count
          creatorId
        }
        publicActions {
          _id
          img {
            url {
              original
            }
          }
          content
        }
        publicEvents {
          featuredEvents {
            _id
            title
            thumbnail {
              url {
                original
              }
            }
          }
          incomingEvents {
            _id
            title
            thumbnail {
              url {
                original
              }
            }
          }
          otherEvents {
            _id
            title
            thumbnail {
              url {
                original
              }
            }
          }
        }
      }
    }
  `,
  GET_DETAIL_USER: gql`
    query GET_DETAIL_USER($id: ID!) {
      user(id: $id) {
        phoneNumber
      }
    }
  `,

  GET_LIST_EVENT_CREATOR: gql`
    query getListEventCreator(
      $status_eq: EventStatus!
      $createdBy_eq: String!
      $limit: Int!
      $page: Int!
      $type_eq: EventType
    ) {
      listEventsByCreator(
        input: {
          filter: { status_eq: $status_eq, createdBy_eq: $createdBy_eq, type_eq: $type_eq }
          orderBy: CreatedAtDesc
          pagination: { limit: $limit, page: $page }
        }
      ) {
        totalItem
        data {
          streamKey
          title
          _id
          artist
          type
          countTicket
          status
          startTime
          description
          thumbnail {
            _id
            url {
              original
            }
          }
          public
          isFeatured
        }
      }
    }
  `,
  GET_LIST_DEVICE: gql`
    query getListDevice($limit: Int!, $page: Int!, $id: String!) {
      getListDevice(input: { filter: { id: $id }, pagination: { limit: $limit, page: $page } }) {
        totalItem
        data {
          deviceName
          createdAt
          address
          userId
        }
      }
    }
  `
};
