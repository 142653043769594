import * as React from 'react';

const UploadIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={800} height={800} viewBox="0 0 24 24" {...props}>
    <title />
    <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
      <path d="M3 12.3v7a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
      <path d="m7.9 6.7 4.1-4 4.1 4" data-name="Right" />
      <path d="M12 16.3V4.8" />
    </g>
  </svg>
);
export default UploadIcon;
