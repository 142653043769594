import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn, getCurRole } from './utils/auth';

function AuthRouter({ children, roles }) {
  const location = useLocation();
  //NOTE change isLoggedIn by get local isLoggedIn
  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  //NOTE change currentRole by get local currentRole
  // const currentRole = getCurRole();
  // if (roles && roles.indexOf(currentRole) === -1) {
  //   return <Navigate to="/" state={{ from: location }} replace />;
  // }

  return children;
}
export default AuthRouter;
