import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
      <div className=''>{t("copyBy")}<span className="ml-1">&copy;</span>{currentYear}</div>
  );
};

export default Footer;
