// import axios from 'axios';
// import { Upload } from 'chunk-file-upload';
// import { API_UPLOAD_FILE } from 'src/utils/url';

// export const HandlerUploadFileApi = async file => {
//   const CHUNK_SIZE = 2 * 1024 * 1024;
//   const totalChunk = Math.ceil(file.size / CHUNK_SIZE);
//   const randomText = (Math.random() + 1)?.toString(36)?.substring(7);
//   const fileId = new Date().getTime() + randomText;
//   const upload = new Upload({
//     lifecycle: {},
//     config: {
//       chunkSize: CHUNK_SIZE
//     },
//     ignores: []
//   });
//   return new Promise((resolve, reject) => {
//     upload.upload({
//       file: {
//         file
//       },
//       request: {
//         uploadFn: async data => {
//           const response = await axios.post(API_UPLOAD_FILE, data, {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//               'uploader-chunks-total': `${totalChunk}`,
//               'uploader-chunk-index': `${data.get('index')}`,
//               'uploader-file-id': `${fileId}`,
//               'uploader-file-name': `${encodeURIComponent(file.name)}`,
//               'Access-Control-Allow-Origin': '*',
//               authorization: `Bearer ` + localStorage.getItem('accessToken')
//             }
//           });
//           // const currentChunk = Number(data.get('index'));
//           // setPercent((currentChunk / (totalChunk - 1)) * 100);
//           if (response?.data) {
//             return resolve(response.data);
//           }
//           return response?.data;
//         }
//       }
//     });
//   });
// };

import axios from 'axios';
import { API_UPLOAD_FILE } from 'src/utils/url';

const uploadURL = API_UPLOAD_FILE || 'https://media.metahall.com.vn/media/upload';

export const HandlerUploadFileApi = file => {
  return new Promise(resolve => {
    const onError = error => {
      const message = error?.response?.data?.message || '';

      if (message.includes('Unsupported file type')) {
        console.log('Unsupported file type');
      }
    };

    (async () => {
      try {
        new Promise(resolve => {
          setTimeout(resolve, 5000);
        });

        const response = await axios.post(
          uploadURL,
          {
            file
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              authorization: `Bearer ` + localStorage.getItem('accessToken')
            }
          }
        );

        resolve(response.data);
      } catch (error) {
        onError(error);
      }
    })();
  });
};

export const getBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.onloadend = function () {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
};
