import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { openNotification } from 'src/components/NotificationBasic';
import { BROADCAST_MUTATION } from 'src/constants/event.graphql';
import { EVENT_QUERY } from 'src/constants/events';
import { convertToISODate } from 'src/utils/date_helper';
import { derivedEarlyBirdPriceInput, derivedEventPriceInput } from 'src/utils/event.hepler';
import { EarlyBirdOption, EventPaymentType } from 'src/utils/type';

export const derivedInitialEarlyBirdForm = eventDetail => {
  const {
    isEarlyBird,
    earlyBirdPriceDay,
    earlyBirdPrice,
    earlyBirdPriceMonth,
    expiredEarlyBird,
    countEarlyBird
  } = eventDetail || {};

  return {
    earlyBird: isEarlyBird ? EarlyBirdOption.ENABLED : EarlyBirdOption.DISABLED,
    isEarlyBird,
    isExpiredEarlyBird: !!expiredEarlyBird,
    isCountEarlyBird: !!countEarlyBird,
    earlyBirdPriceDay: earlyBirdPriceDay && Number(earlyBirdPriceDay),
    earlyBirdPrice: earlyBirdPrice && Number(earlyBirdPrice),
    earlyBirdPriceMonth: earlyBirdPriceMonth && Number(earlyBirdPriceMonth),
    expiredEarlyBird,
    countEarlyBird: countEarlyBird && Number(countEarlyBird)
  };
};

const derivedCreatePracticeInput = (values, hasPrice) => {
  let additionalField = {};
  if (hasPrice) {
    const earlyBirdInput = derivedEarlyBirdPriceInput(values);
    const priceInput = derivedEventPriceInput(values);
    additionalField = {
      ...earlyBirdInput,
      ...priceInput
    };
  }

  return {
    thumbnail: values?.thumbnail?._id,
    video: values?.video?._id,
    name: values?.name,
    description: values?.description || '',
    startTime: convertToISODate(values.startTime),
    ...additionalField
  };
};

export const derivedInitialPriceForm = eventDetail => {
  return {
    priceDay: eventDetail?.priceDay,
    priceMonth: eventDetail?.priceMonth,
    price: eventDetail?.price,
    priceLimited: eventDetail?.priceLimited || undefined,
    is_priceDay: !!eventDetail?.priceDay,
    is_priceMonth: !!eventDetail?.priceMonth,
    is_price: !!eventDetail?.price,
    is_priceLimited: !!eventDetail?.priceLimited
  };
};

const useAppendPractice = eventId => {
  const formikRef = useRef();
  const navigate = useNavigate();

  const { data: detailEventResponse } = useQuery(EVENT_QUERY.GET_DETAIL_EVENT, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        eventId
      }
    }
  });

  const [createEvenSeriFn] = useMutation(BROADCAST_MUTATION.CREATE_EVENT_SERIES, {
    refetchQueries: ['getEventInfo']
  });

  const [addSeriesFn] = useMutation(BROADCAST_MUTATION.ADD_SERIES, {
    refetchQueries: ['getEventInfo']
  });

  const submitForm = useCallback(() => {
    if (formikRef?.current) {
      formikRef?.current?.submitForm();
    }
  }, [formikRef]);

  const eventDetail = detailEventResponse?.getEventInfo;

  const handleSubmit = useCallback(
    async values => {
      try {
        const hasPrice =
          (eventDetail?.paymentType || EventPaymentType.FREE) === EventPaymentType.PURCHASE;

        const input = derivedCreatePracticeInput(values, hasPrice);
        const response = await createEvenSeriFn({
          variables: {
            input
          }
        });

        // .then(res => {
        //   const { data, errors } = res;
        //   console.log(data);
        //   if (errors) {
        //     openNotification('error', errors[0].message);
        //   } else {
        //     openNotification('success', 'Thêm tập thành công');
        //     navigate(`/liveshows/detail/${eventId}`);
        //   }
        // });

        console.log('respnsize', response);

        const seriesId = response?.data?.series?.createEventSeries._id;
        if (seriesId) {
          await addSeriesFn({
            variables: {
              input: {
                eventId,
                seriesId
              }
            }
          }).then(res => {
            const { data, errors } = res;
            console.log(data);
            if (errors) {
              openNotification('error', errors[0].message);
            } else {
              openNotification('success', 'Thêm tập thành công');
              navigate(`/liveshows/detail/${eventId}`);
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [eventId, eventDetail]
  );

  const handleCancel = useCallback(() => {
    return navigate(-1);
  }, [navigate, eventId]);

  const initialFields = useMemo(() => {
    const paymentType = eventDetail?.paymentType || EventPaymentType.FREE;
    let additionalField = {};

    if (paymentType === EventPaymentType.PURCHASE) {
      additionalField = {
        ...derivedInitialEarlyBirdForm(eventDetail),
        ...derivedInitialPriceForm(eventDetail)
      };
    }

    return {
      name: eventDetail?.name,
      description: eventDetail?.description,
      thumbnail: eventDetail?.poster,
      startTime: eventDetail?.startTime,
      ...additionalField
    };
  }, [detailEventResponse]);

  return {
    formikRef,
    submitForm,
    handleSubmit,
    handleCancel,
    initialFields,
    eventDetail
  };
};

export default useAppendPractice;
