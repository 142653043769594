import { gql } from '@apollo/client';

export const ANALYTICS_QUERY = {
  LIST_ORDER: gql`
    query listOrder($input: ListOrderInput!) {
      listOrder(input: $input) {
        totalItem
        totalAmount
        data {
          _id
          code
          status
          amount
          description
          infoCouponCode {
            code
            name
            type
            reducedAmount
          }
          discountAmount
          subTotal
          userId {
            fullName
          }
          transaction {
            gateway
            id
            time
          }
          items {
            id
            quantity
            price
            type
            itemType
            event {
              name
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  `,
  DETAIL_ORDER: gql`
    query detailOrder($input: DetailOrderInput!) {
      detailOrder(input: $input) {
        _id
        code
        status
        amount
        description
        # couponCode
        discountAmount
        subTotal
        userId {
          fullName
        }
        transaction {
          gateway
          id
          time
        }
        items {
          id
          quantity
          price
          type
          itemType
          event {
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  `,
  ANALYTIC_CHART: gql`
    query getAnalyticsPageCms($input: GetAnalyticsPageInput!) {
      getAnalyticsPageCms(input: $input) {
        data {
          analytics {
            views
            eventCount
            soldTicketCount
            interactionCount
            applyingAdsCount
            promotionCount
            revenue
            metaDiamondCount
            percentEvent
            percentTicket
            percentInteraction
            percentRevenue
            percentView
          }
          chart {
            labels
            datasets {
              label
              data
            }
          }
          revenues {
            _id
            __typename
            type
            startTime
            views
            accessCount
            name
            revenue {
              count
              venue
              conversionRate
              type
            }
          }
          pagePath {
            value
            oneValue
          }
        }
      }
    }
  `,
  ANALYTIC_TOP_EVENT: gql`
    query getTopEventPage($input: GetTopEventPageInput!) {
      getTopEventPage(input: $input) {
        _id
        name
        description
        poster {
          _id
          url
        }
        page {
          _id
          displayName
        }
      }
    }
  `,
  ANALYTIC_VIEW: gql`
    query analyticViewPages($input: GetAnalyticsPageInput!) {
      analyticViewPages(input: $input) {
        _id
        startDate
        endDate
        viewNow
        viewPre
      }
    }
  `
};
