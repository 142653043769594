import { useField, useFormikContext } from 'formik';
import Dropdown from './dropdown';

export default function DropdownField(props) {
  const { options, disabled, onChange, placeholder} = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);

  return (
    <Dropdown
      disabled={disabled}
      value={field?.value}
      options={options}
      placeholder={placeholder}
      onChange={value => {
        setFieldTouched(field?.name, true);
        setFieldValue(field?.name, value?.value);
        onChange && onChange(value?.value);
      }}
    />
  );
}
