import dayjs from 'dayjs';
import * as Yup from 'yup';
import {
  CouponCodeType,
  EarlyBirdOption,
  EventPaymentType,
  TypeVoucher,
  UserVerifyType
} from './type';

Yup.addMethod(Yup.string, 'minDate', function (minDate, errorMessage) {
  return this.test(`greaterDate`, errorMessage, function (value) {
    const { path, createError } = this;
    const inputDate = dayjs(value);
    if (inputDate.isBefore(minDate)) {
      return createError({ path, message: errorMessage });
    }
    return true;
  });
});

export const validateCreateBanner = Yup.object().shape({
  title: Yup.string().required('Vui lòng nhập tiêu đề'),
  link: Yup.string().url('Vui lòng nhập đường dẫn'),
  bannerImage: Yup.object().required('Vui lòng tải lên hình ảnh')
});

export const validateCreatePayment = Yup.object().shape({
  amountPaid: Yup.string().required('Vui lòng nhập số tiền thanh toán').nullable(),
  contentPay: Yup.string().required('Vui lòng nhập nội dung thanh toán'),
  file: Yup.object().required('Vui lòng tải lên hình ảnh'),
  paymentPeriodStart: Yup.string().required('Vui lòng nhập giờ diễn ra')
});

export const validateCreateEvent = Yup.object().shape({
  title: Yup.string().required('Vui lòng nhập tiêu đề'),
  startTime_time: Yup.string().required('Vui lòng nhập giờ diễn ra'),
  startTime_date: Yup.string().required('Vui lòng nhập ngày diễn ra'),
  description: Yup.string().required('Vui lòng nhập mô tả'),
  category: Yup.string().required('Vui lòng nhập chuyên mục'),
  subCategories: Yup.array()
    .required('Vui lòng nhập thể loại')
    .min(1, 'Vui lòng nhập thể loại')
    .max(2, 'Tối đa 2 thể loại'),
  image: Yup.object().required('Vui lòng tải lên hình ảnh'),
  // price: Yup.number().required('Vui lòng nhập giá vé'),
  agree: Yup.boolean()
    .required('Bạn phải đồng ý với điều khoản')
    .oneOf([true], 'Bạn phải đồng ý với điều khoản')
});

export const validateUploadImage = Yup.object().shape({
  image: Yup.object().required('Vui lòng tải lên ảnh'),
  file: Yup.object().required('Vui lòng tải lên file')
});

const validateCreateEventShape = {
  name: Yup.string().required('Vui lòng nhập tên sự kiện'),
  category: Yup.string().required('Vui lòng nhập tên thể loại'),
  subCategory: Yup.array()
    .required('Vui lòng nhập loại hình')
    .min(1, 'Vui lòng nhập ít nhất loại hình'),

  paymentType: Yup.string().required('Vui lòng nhập phương thức thanh toán'),
  description: Yup.string().required('Vui lòng nhập mổ tả sự kiện'),
  poster: Yup.object().required('Vui lòng tải lên ảnh sự kiện'),
  isAgree: Yup.boolean()
    .required('Bạn chưa lựa chọn đồng ý với Cam kết Quyền Sở Hữu Tuệ')
    .oneOf([true], 'Bạn chưa lựa chọn đồng ý với Cam kết Quyền Sở Hữu Tuệ')
};

export const validateCreateBroadcastEvent = Yup.object().shape({
  ...validateCreateEventShape,
  expiredTime: Yup.date().when(['isExpiredTimeForever'], {
    is: (isExpiredTimeForever, expiredTime) => {
      return !isExpiredTimeForever && !expiredTime;
    },
    then: Yup.date().min(dayjs().add(32, 'days'), 'Thời hạn chương trình quá ngắn')
  }),
  numberSeries: Yup.number().min(1, 'Ít nhất 1 tập').required('Vui lòng nhập số tập'),
  paymentType: Yup.string().required('Vui lòng chọn hình thức thu phí'),
  video: Yup.object().when(['numberSeries'], {
    is: numberSeries => {
      return numberSeries === 1;
    },
    then: Yup.object().required('Vui lòng tải lên video')
  })
});

export const validationCreateArtistSchema = Yup.object().shape({
  artistName: Yup.string().required('Vui lòng nhập tên Nghệ sĩ'),
  avatarId: Yup.object().required('Vui lòng tải lên ảnh mặt Nghệ sĩ')
});

export const validateUpdateBroadcastEvent = Yup.object().shape({
  ...validateCreateEventShape,
  paymentType: Yup.string().required('Vui lòng chọn hình thức thu phí')
});

const earlyBirdValidationFields = {
  countEarlyBird: Yup.number().when(['earlyBird', 'isExpiredEarlyBird'], {
    is: (earlyBird, isExpiredEarlyBird) => {
      return earlyBird === EarlyBirdOption.ENABLED && !isExpiredEarlyBird;
    },
    then: Yup.number().required('Điền ít nhất 1 tiêu chí').min(0, 'Vui lòng nhập số dương')
  }),
  expiredEarlyBird: Yup.string()
    .nullable()
    .when(['earlyBird', 'isCountEarlyBird'], {
      is: (earlyBird, isCountEarlyBird) => {
        return earlyBird === EarlyBirdOption.ENABLED && !isCountEarlyBird;
      },
      then: Yup.string().required('Điền ít nhất 1 tiêu chí')
    })
};

export const requestVerifyUserSchema = Yup.object().shape({
  verifyType: Yup.string().required('Vui lòng nhập'),
  userName: Yup.string()
    .min(1, 'Tên hiển thị phải gồm ít nhất 5 ký tự')
    .max(255, 'Tên hiển thị nhiều nhất 255 ký tự')
    .required('Tên hiển thị không được để trống'),
  phoneNumber: Yup.string()
    .required('Vui lòng nhập Số Điện Thoại')
    .max(12, 'Số Điện Thoại không hợp lệ')
    .matches(/^([+]|84|0)+([3|5|7|8|9])+([0-9]{8})$\b/g, 'Số Điện Thoại không hợp lệ'),
  identifyCardNumber: Yup.string().required('Vui lòng nhập CCCD/ĐKKD').nullable(),
  front: Yup.object()
    .when(['verifyType'], {
      is: verifyType => {
        return verifyType === UserVerifyType.PERSONAL;
      },
      then: Yup.object().required('Vui lòng tải lên ảnh mặt trước CCCD')
    })
    .when(['verifyType'], {
      is: verifyType => {
        return verifyType === UserVerifyType.GROUPS;
      },
      then: Yup.object().required('Vui lòng tải lên ảnh ĐKKD')
    }),
  back: Yup.object()
    .when(['verifyType'], {
      is: verifyType => {
        return verifyType === UserVerifyType.PERSONAL;
      },
      then: Yup.object().required('Vui lòng tải lên ảnh mặt sau CCCD')
    })
    .when(['verifyType'], {
      is: verifyType => {
        return verifyType === UserVerifyType.GROUPS;
      },
      then: Yup.object().nullable()
    }),
  email: Yup.string().required('Vui lòng nhập email').email('Email không đúng định dạng'),
  bankType: Yup.string().required('Vui lòng chọn phương thức thanh toán'),
  bankName: Yup.string().required('Vui lòng điền thông tin ngân hàng'),
  cardNumber: Yup.string().required('Vui lòng nhập số tài khoản'),
  cardName: Yup.string().required('Vui lòng nhập tên tài khoản'),
  taxCode: Yup.string()
    .required('Vui lòng nhập mã số thuế')
    .matches(/^[0-9]*$/, 'Mã số thuế không hợp lệ')
  // isAdult: Yup.boolean()
  //   .required('Bạn phải đồng ý với cam kết')
  //   .oneOf([true], 'Bạn phải đồng ý với cam kết'),
  // isAcceptRule: Yup.boolean()
  //   .required('Bạn phải đồng ý với điều khoản')
  //   .oneOf([true], 'Bạn phải đồng ý với điều khoản')
});

export const validateCreatePurchaseBroadcast = Yup.object().shape({
  price: Yup.number()
    .nullable()
    .when(['is_priceLimited', 'paymentType', 'is_priceMonth', 'is_priceDay'], {
      is: (isChecked, paymentType) => {
        return isChecked && paymentType === EventPaymentType.PURCHASE;
      },
      then: Yup.number()
        .nullable()
        .required('Không được để trống thông tin này')
        .min(0, 'Giá tiền phải là số dương')
    })
    .when(['is_price', 'is_priceMonth', 'is_priceDay', 'paymentType'], {
      is: (is_price, is_priceMonth, is_priceDay, paymentType) => {
        return !is_price && !is_priceMonth && !is_priceDay && paymentType !== EventPaymentType.FREE;
      },
      then: () => {
        return Yup.number().nullable().required('Chọn ít nhất 1 trường');
      }
    }),
  priceDay: Yup.number()
    .nullable()
    .when(['is_priceDay', 'paymentType'], {
      is: (is_priceDay, paymentType) => {
        return paymentType !== EventPaymentType.FREE && is_priceDay;
      },
      then: Yup.number()
        .nullable()
        .required('Không được để trống thông tin này')
        .min(0, 'Giá tiền phải là số dương')
    }),
  priceMonth: Yup.number()
    .nullable()
    .when(['is_priceMonth', 'paymentType'], {
      is: (is_priceMonth, paymentType) => {
        return paymentType !== EventPaymentType.FREE && is_priceMonth;
      },
      then: Yup.number()
        .nullable()
        .required('Không được để trống thông tin này')
        .min(0, 'Giá tiền phải là số dương')
    }),
  earlyBirdPrice: Yup.number()
    .nullable()
    .when(['earlyBird', 'price', 'is_price', 'paymentType'], {
      is: (earlyBird, is_price, paymentType) => {
        return (
          earlyBird === EarlyBirdOption.ENABLED && is_price && paymentType !== EventPaymentType.FREE
        );
      },
      then: Yup.number()
        .nullable()
        .required('Không được để trống giá EARLY BIRD')
        .min(0, 'Giá tiền phải là số dương')
        .lessThan(Yup.ref('price'), 'Giá Early BIRD phải nhỏ hơn giá vé chính thức')
    }),
  earlyBirdPriceDay: Yup.number()
    .nullable()
    .when(['earlyBird', 'priceDay', 'is_priceDay', 'paymentType'], {
      is: (earlyBird, is_priceDay, paymentType) => {
        return (
          earlyBird === EarlyBirdOption.ENABLED &&
          is_priceDay &&
          paymentType !== EventPaymentType.FREE
        );
      },
      then: Yup.number()
        .nullable()
        .required('Không được để trống giá EARLY BIRD')
        .min(0, 'Giá tiền phải là số dương')
        .lessThan(Yup.ref('priceDay'), 'Giá Early BIRD phải nhỏ hơn giá vé chính thức')
    }),
  earlyBirdPriceMonth: Yup.number()
    .nullable()
    .when(['earlyBird', 'priceMonth', 'is_priceMonth', 'paymentType'], {
      is: (earlyBird, is_priceMonth, paymentType) => {
        return (
          earlyBird === EarlyBirdOption.ENABLED &&
          is_priceMonth &&
          paymentType !== EventPaymentType.FREE
        );
      },
      then: Yup.number()
        .nullable()
        .required('Không được để trống giá EARLY BIRD')
        .min(0, 'Giá tiền phải là số dương')
        .lessThan(Yup.ref('priceMonth'), 'Giá Early BIRD phải nhỏ hơn giá vé chính thức')
    }),
  ...earlyBirdValidationFields
});

export const validateCreateLivestreamEvent = Yup.object().shape({
  ...validateCreateEventShape,
  startTime: Yup.string()
    // @ts-ignore
    .minDate(
      dayjs().add(2, 'days'),
      'Thời gian diễn ra Sự kiện phải sau tối thiểu 48 giờ kể từ thời điểm tạo'
    )
    .required('Vui lòng nhập giờ diễn ra'),
  paymentType: Yup.string().required('Vui lòng chọn hình thức thu phí')
});
export const validateEditLivestreamEvent = Yup.object().shape({
  ...validateCreateEventShape,
  paymentType: Yup.string().required('Vui lòng chọn hình thức thu phí')
});

export const validateCreatePracticeShapes = {
  name: Yup.string().nullable().required('Vui lòng nhập tên tập'),
  description: Yup.string().required('Vui lòng nhập miêu tả'),
  thumbnail: Yup.object().nullable().required('Vui lòng tải lên ảnh đại diện'),
  priceDay: Yup.number().when('is_priceDay', (isEnabled, field) => {
    return isEnabled
      ? field.min(0, 'Giá tiền phải là số dương').required('Không được để trống thông tin này')
      : field;
  }),
  priceMonth: Yup.number().when('is_priceMonth', (isEnabled, field) => {
    return isEnabled
      ? field.min(0, 'Giá tiền phải là số dương').required('Không được để trống thông tin này')
      : field;
  }),
  price: Yup.number().when('is_price', (isEnabled, field) => {
    return isEnabled
      ? field.min(0, 'Giá tiền phải là số dương').required('Không được để trống thông tin này')
      : field;
  })
};

export const validateCreatePractice = Yup.object().shape({
  ...validateCreatePracticeShapes,
  video: Yup.object().nullable().required('Vui lòng tải lên video')
});

export const createPracticeSchema = Yup.object().shape({
  video: Yup.object().nullable().required('Vui lòng tải lên video'),
  ...validateCreatePracticeShapes
});

export const validateLivestreamPrice = mustHasPrice => {
  const priceValidation = mustHasPrice
    ? {
        price: Yup.number()
          .min(0, 'Giá tiền phải là số dương')
          .required('Không được để trống thông tin này'),
        earlyBirdPrice: Yup.number().when(['earlyBird'], {
          is: earlyBird => {
            return earlyBird === EarlyBirdOption.ENABLED;
          },
          then: Yup.number()
            .required('Không được để trống thông tin này')
            .min(0, 'Giá tiền phải là số dương')
            .lessThan(Yup.ref('price'), 'Giá Early BIRD phải nhỏ hơn giá vé chính thức')
        }),
        ...earlyBirdValidationFields
      }
    : {};

  return Yup.object().shape({
    ...priceValidation,
    priceDay: Yup.number().when(['is_priceDay', 'paymentType'], {
      is: (isChecked, paymentType) => {
        return isChecked && paymentType === EventPaymentType.PURCHASE;
      },
      then: Yup.number()
        .required('Không được để trống thông tin này')
        .min(0, 'Giá tiền phải là số dương')
    }),
    priceMonth: Yup.number().when(['is_priceMonth', 'paymentType'], {
      is: (isChecked, paymentType) => {
        return isChecked && paymentType === EventPaymentType.PURCHASE;
      },
      then: Yup.number()
        .required('Không được để trống thông tin này')
        .min(0, 'Giá tiền phải là số dương')
    }),
    priceLimited: Yup.number()
      .when(['is_priceLimited', 'paymentType', 'is_priceMonth', 'is_priceDay'], {
        is: (isChecked, paymentType) => {
          return isChecked && paymentType === EventPaymentType.PURCHASE;
        },
        then: Yup.number()
          .required('Không được để trống thông tin này')
          .min(0, 'Giá tiền phải là số dương')
      })
      .when(['is_priceLimited', 'is_priceMonth', 'is_priceDay', 'sellMethod'], {
        is: (is_priceLimited, is_priceMonth, is_priceDay, sellMethod) => {
          return (
            !is_priceLimited &&
            !is_priceMonth &&
            !is_priceDay &&
            sellMethod == EventPaymentType.PURCHASE
          );
        },
        then: () => {
          return Yup.number().nullable().required('Chọn ít nhất 1 trường');
        }
      }),
    isGenerateTicket: Yup.string().when('paymentType', (paymentType, field) => {
      return paymentType === EventPaymentType.PURCHASE
        ? field.required('Không được để trống thông tin này')
        : field;
    })
  });
};

export const validateCreateVoucher = type => {
  const validateQuantity = type
    ? {
        quantity: Yup.number().when(['type'], {
          is: type => {
            return type === TypeVoucher.COMBO;
          },
          then: Yup.number()
            .required('Không được để trống thông tin này')
            .min(0, 'Số lượng phải là số dương')
        })
      }
    : {};

  return Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập tên chiến dịch'),
    code: Yup.string().when(['type'], {
      is: type => {
        return type === TypeVoucher.CODE;
      },
      then: Yup.string()
        .required('Vui lòng nhập mã code')
        .matches(/^[a-zA-Z0-9]{6,8}$/, 'Mã từ 6 đến 8 ký tự, không bao gồm ký tự đặc biệt')
    }),
    startTime: Yup.string().nullable().required('Vui lòng nhập ngày bắt đầu'),
    endTime: Yup.string().nullable().required('Vui lòng nhập ngày kết thúc'),
    discountAmount: Yup.number()
      .nullable()
      .when(['codeType'], {
        is: discountType => {
          return discountType === CouponCodeType.AMOUNT;
        },
        then: Yup.number()
          .nullable()
          .required('Vui lòng nhập số tiền giảm giá')
          .min(0, 'Số tiền giảm giá phải là số dương')
      }),
    discountPercent: Yup.number()
      .nullable()
      .when(['codeType'], {
        is: discountType => {
          return discountType === CouponCodeType.PERCENT;
        },
        then: Yup.number()
          .nullable()
          .required('Vui lòng nhập phần trăm giảm giá')
          .min(0, 'Phần trăm giảm giá phải là số dương')
          .max(100, 'Phần trăm giảm giá phải nhỏ hơn 100')
      }),
    maxDiscount: Yup.number()
      .nullable()
      .when(['codeType'], {
        is: discountType => {
          return discountType === CouponCodeType.PERCENT;
        },
        then: Yup.number().nullable().required('Vui lòng nhập số tiền giảm tối đa')
      }),
    minTotalAmount: Yup.number().nullable().required('Vui lòng nhập số tiền tối thiểu'),
    limitedVoucher: Yup.number().nullable().required('Vui lòng nhập tổng số voucher phát hành'),
    maxUsed: Yup.number()
      .nullable()
      .required('Vui lòng nhập số lượng voucher được phép  áp dụng  trên mỗi tài khoản'),
    ...validateQuantity
  });
};


export const validateRegisterPaperId = Yup.object().shape({
  registrationPaperId: Yup.object().nullable().required('Vui lòng tải lên video'),

})