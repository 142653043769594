import dayjs from 'dayjs';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import styled from 'styled-components';
import DatePicker from '.';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 40px;
  box-shadow: rgb(223 223 223) 0 0 3px 3px inset;
  color: black;
  border: none;
  font-weight: 300;

  position: relative;

  .ant-picker {
    width: 100%;
    height: 40px;
  }

  input {
    text-align: left;
    font-weight: 400;

    width: 100%;
  }
`;

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);
  console.log('field value', field.value);

  const value = field.value ? moment(field.value) : undefined;
  console.log('value', dayjs(props?.minDate).format('DD/MM/YYYY'));

  return (
    <Wrapper>
      <DatePicker
        value={value}
        minDate={props?.minDate || new Date()}
        maxDate={props?.maxDate}
        format="HH:mm - DD/MM/YYYY"
        showTime={true}
        onChange={date => {
          const dateString = date?.toString() || undefined;
          setFieldTouched(field.name, true);
          setFieldValue(field.name, dateString);
          props?.onChange && props?.onChange(date);
        }}
        disabled={props?.disabled}
        placeholder={props?.placeholder}
      />
    </Wrapper>
  );
};

export default DatePickerField;
