import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonBack = () => {
  const navigate = useNavigate();
  return (
    <Button
      className="btn btn-add"
      onClick={() => {
        navigate(-1);
      }}
    >
      Quay lại
    </Button>
  );
};

export default ButtonBack;
