import { useField, useFormikContext } from 'formik';
import InputCurrency from './inputCurrentcy';

export const PriceInputField = props => {
  let wrapperClass = props?.className;
  if (props?.disabled) {
    wrapperClass += ' !bg-[#efefef]';
  }
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);
  // useEffect(() => {
  //   if (props?.disabled) {
  //     setFieldValue(field.name, '');
  //   }
  // }, [field.value, props?.disabled]);

  return (
    <div className={wrapperClass}>
      <InputCurrency
        disable={props?.disabled}
        bgColor={props?.bgColor}
        defaultValue={field.value}
        onChange={value => {
          let _value = value;
          if (isNaN(_value)) {
            _value = null;
          }

          setFieldValue(field.name, _value);
          setFieldTouched(field.name, true);
        }}
      />
    </div>
  );
};

export default PriceInputField;
