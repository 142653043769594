import { useNavigate } from "react-router-dom";

const useLogOut = () => {
    const navigate = useNavigate()
    navigate('/login')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  return [];
};

export default useLogOut;