import React, { useEffect, useRef, useState } from 'react';
// import { BsArrowLeft } from "react-icons/bs";
import { useLocation } from 'react-router-dom';
import SidebarHeader from 'src/components/Sidebar/SidebarHeader';
import SidebarItem from 'src/components/Sidebar/SidebarItem';
import { converPathNameForMenu, menus } from 'src/sidebarData';
import { Can } from '../../casl/Abilities';
import './index.css';

const Sidebar = props => {
  const { open } = props;
  const layer = useRef(1);
  const [curMenuActive, setCurMenuActive] = useState('');
  const location = useLocation();
  useEffect(() => {
    const tempPath = converPathNameForMenu(location.pathname);
    setCurMenuActive(tempPath);
  }, [location]);
  return (
    <div className={`bg-dark-purple h-screen duration-300 sticky top-0 ${open ? 'w-80' : 'w-20'}`}>
      {/* <BsArrowLeft
        onClick={() => setOpen(!open)}
        className={`bg-white text-dark-purple text-2xl rounded-full absolute -right-3 top-12 border border-dark-purple cursor-pointer ${
          !open && "rotate-180"
        }`}
      /> */}
      <div className="h-screen p-5 pt-8 overflow-x-hidden custom-scroll-bar ">
        <SidebarHeader open={open} />

        {/* <SearchItem open={open} /> */}

        <div className="pt-2">
          {menus.map((item, index) => (
            <>
              <Can
                I={item?.can?.[0] || 'read'}
                a={item?.can?.[1] || item?.cannot || 'all'}
                key={index}
              >
                <SidebarItem
                  open={open}
                  item={item}
                  layer={layer.current}
                  curMenuActive={curMenuActive}
                />
              </Can>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
