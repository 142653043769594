import { gql } from '@apollo/client';

export const VERIFY_USER_FRAGMENT = gql`
  fragment VerifyUserFragment on VerifyUserType {
    _id
    userId
    verifyType
    userName
    email
    phoneNumber
    status
  }
`;

export const MEDIA_FRAGMENT = gql`
  fragment MediaFragment on Media {
    _id
    url
    userId
    name
  }
`;

export const BANK_FRAGMENT = gql`
  fragment BankFragment on Banks {
    bankName
    cardName
    cardNumber
    taxCode
    swiftCode
    photoContractMediaIds
    branch
  }
`;

export const EVENT_DTO_SERIES_FRAGMENT = gql`
  ${MEDIA_FRAGMENT}
  fragment EventSeriesDtoTypeFragment on EventSeriesDtoType {
    name
    description
    seriesNumber
    duration
    order
    thumbnail {
      ...MediaFragment
    }
    video {
      ...MediaFragment
    }
    _id
    price
    priceDay
    priceMonth
    isApproved
    startTime
    isEarlyBird
    earlyBirdPrice
    earlyBirdPriceDay
    earlyBirdPriceMonth
    expiredEarlyBird
    countEarlyBird
  }
`;

export const USER_DTO_FRAGMENT = gql`
  ${MEDIA_FRAGMENT}
  ${VERIFY_USER_FRAGMENT}
  fragment UserFragMent on UserDtoType {
    createdAt
    deletedAt
    deletedBy
    updatedAt
    _id
    provider
    fullName
    email
    phoneNumber
    gender
    birthday
    address
    statusVerify
    avatarId {
      ...MediaFragment
    }
    active
    verified
    photoIdentityMediaIds {
      ...MediaFragment
    }
    twoFactorAuthenticationSecret
    country
    info {
      ...VerifyUserFragment
    }
    verifyPhone
  }
`;

export const EVNET_DTO_FRAGMENT = gql`
  ${EVENT_DTO_SERIES_FRAGMENT}
  fragment EventDtoTypeFragment on EventDtoType {
    _id
    name
    category {
      _id
      name
    }
    subCategory {
      _id
      name
    }
    description
    startTime
    venue
    slug
    band
    director
    poster {
      url
    }
    trailer {
      url
    }
    type
    page {
      _id
      fullName
      displayName
    }
    price
    priceDay
    priceMonth
    streamKey
    streamUrl
    isBeingLive
    artists {
      artists {
        _id
        name
        avatar {
          url
        }
      }
      page {
        avatarId {
          url
        }
        _id
        displayName
      }
      type
    }
    series {
      ...EventSeriesDtoTypeFragment
    }
    status
    seriesApproved {
      ...EventSeriesDtoTypeFragment
    }
  }
`;
