const IMAGE_SERVER = process.env.REACT_APP_MEDIA_URL || '';
const RESIZE_SERVER = process.env.REACT_APP_RESIZE_IMAGE || '';
import Avatar from 'src/assets/flower.jpg';

export const getServerImage = (src, width, height, defaultImage) => {
  if (!src) {
    return defaultImage ? defaultImage : Avatar;
  }

  if (width && height) {
    const renderWidth = Math.round(width * SCALE_PERCENT);
    const renderHeight = Math.round(height * SCALE_PERCENT);

    return RESIZE_SERVER + `/w${renderWidth}/h${renderHeight}/${src.replace('storage/', '')}`;
  }

  return `${IMAGE_SERVER}/${src}`;
};

export const getDownloadTemplateURL = fileName => {
  return IMAGE_SERVER + '/storage/template/' + fileName;
};
