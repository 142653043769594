import { useCallback, useMemo, useRef, useState } from 'react';
import TrashIcon from 'src/components/Icons/trash';
import UploadIcon from 'src/components/Icons/upload';
import { HandlerUploadFileApi, getBase64 } from 'src/helper';
import { getServerImage } from 'src/utils/image';

const DragDropUpload = ({ value, onChange, className, disabled }) => {
  const [localImage, setLocalImage] = useState(null);
  const ref = useRef(null);

  const imageUrl = useMemo(() => {
    if (localImage) {
      return localImage;
    }
    if (value?.url) {
      return getServerImage(value?.url);
    }
    return null;
  }, [localImage, value]);

  const handleClear = useCallback(() => {
    if (disabled) {
      return;
    }
    setLocalImage(null);
    onChange(undefined);
  }, [onChange, setLocalImage, disabled]);

  const handleUpload = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      if (disabled) {
        return;
      }
      const file = event?.target?.files[0];
      if (!file) return;
      getBase64(file, base64 => {
        setLocalImage(base64);
      });

      HandlerUploadFileApi(file).then(data => {
        onChange(data);
      });
    },
    [onChange]
  );

  return (
    <div
      ref={ref}
      className={`drag-drop-upload-wrapper flex-col group relative flex min-h-[200px] cursor-pointer items-center gap-2 bg-gray-200 ${
        imageUrl ? '' : 'p-4'
      } ${className} text-center`}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 border-[1.2rem] border-[#9a9da4] bg-transparent opacity-40" />

      {imageUrl ? (
        <>
          {/*@ts-ignore*/}
          <img alt="Image" src={imageUrl} className={`h-full w-full object-cover `} />
          <div
            className="invisible absolute bottom-0 z-10 flex  h-4 w-full cursor-pointer items-center justify-center gap-1 bg-gray-500 py-2 text-[0px] text-white transition-all group-hover:!visible group-hover:text-base"
            onClick={handleClear}
          >
            <TrashIcon color="currentcolor" className="inline" /> <span>Xóa ảnh</span>
          </div>
        </>
      ) : (
        <>
          <UploadIcon width="30px" height="30px" className="mt-[15%] opacity-0" />
          <button className="w-[100px] rounded-2xl border-[2px] border-solid border-black p-1 text-center">
            {' '}
            Chọn Ảnh
          </button>
          <p className="text-base">Hoặc kéo và thả ảnh vào trong khung này để tải lên</p>
        </>
      )}

      <input
        disabled={disabled}
        type="file"
        onChange={handleUpload}
        accept="image/*"
        className="absolute inset-0 opacity-0 !h-full"
      />
    </div>
  );
};

export default DragDropUpload;
