import { b64DecodeUnicode, b64EncodeUnicode } from './base64-utils';

export const setLocaleLocal = newLocale => {
  const curLocale = b64EncodeUnicode(newLocale);
  window.localStorage.setItem('localeStored', curLocale);
};

export const getLocaleLocal = () => {
  const localeStored = window.localStorage.getItem('localeStored') || '';
  const curLocale = b64DecodeUnicode(localeStored);
  if (localeStored === '') {
    setLocaleLocal('vn');
    return 'vn';
  }
  return curLocale;
};
