const BannerIcon = (props) => (
  <span {...props} role="img">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4H22V17H2V4Z"
        stroke="#FCFCFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2 9L18 10.5L16.2 12V9ZM7.8 12L6 10.5L7.8 9V12Z"
        stroke="#FCFCFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 20.5C10 20.7761 10.2239 21 10.5 21C10.7761 21 11 20.7761 11 20.5C11 20.2239 10.7761 20 10.5 20C10.2239 20 10 20.2239 10 20.5Z"
        stroke="#FCFCFD"
        strokeWidth="1.5"
      />
      <path
        d="M7 20.5C7 20.7761 7.22386 21 7.5 21C7.77614 21 8 20.7761 8 20.5C8 20.2239 7.77614 20 7.5 20C7.22386 20 7 20.2239 7 20.5Z"
        stroke="#FCFCFD"
        strokeWidth="1.5"
      />
      <path
        d="M13 20.5C13 20.7761 13.2239 21 13.5 21C13.7761 21 14 20.7761 14 20.5C14 20.2239 13.7761 20 13.5 20C13.2239 20 13 20.2239 13 20.5Z"
        stroke="#FCFCFD"
        strokeWidth="1.5"
      />
      <path
        d="M16 20.5C16 20.7761 16.2239 21 16.5 21C16.7761 21 17 20.7761 17 20.5C17 20.2239 16.7761 20 16.5 20C16.2239 20 16 20.2239 16 20.5Z"
        stroke="#FCFCFD"
        strokeWidth="1.5"
      />
    </svg>
  </span>
);

export default BannerIcon;
