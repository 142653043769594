import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import React from 'react';
import { getServerImage } from 'src/utils/image';
import bg_top from '../../assets/top10mobile/bg_top10.svg';
import top1 from '../../assets/top10mobile/top1.svg';
import top10 from '../../assets/top10mobile/top10.svg';
import top2 from '../../assets/top10mobile/top2.svg';
import top3 from '../../assets/top10mobile/top3.svg';
import top4 from '../../assets/top10mobile/top4.svg';
import top5 from '../../assets/top10mobile/top5.svg';
import top6 from '../../assets/top10mobile/top6.svg';
import top7 from '../../assets/top10mobile/top7.svg';
import top8 from '../../assets/top10mobile/top8.svg';
import top9 from '../../assets/top10mobile/top9.svg';
import './styles.less';
const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: 'black'
};
const TopEvent = ({ topEvents }) => {
  const bg = [top1, top2, top3, top4, top5, top6, top7, top8, top9, top10];

  return (
    <>
      <>
        <div className="py-3 text-xl font-semibold uppercase top-event">
          Top chương trình bán chạy
        </div>
      </>
      <Carousel
        arrows={true}
        nextArrow={<RightOutlined />}
        prevArrow={<LeftOutlined />}
        slidesToShow={3}
      >
        {topEvents?.map((event, index) => {
          return (
            <>
              <div className="relative px-2 ">
                <img
                  src={getServerImage(event?.poster?.url)}
                  preview={false}
                  style={{ objectFit: 'cover' }}
                  className="!h-[250px] w-full relative rounded-t-xl shadow-md"
                />{' '}
                <div className="absolute bottom-6 z-10 flex left-[25%] text-white">
                  {event?.name}
                </div>
                <div className="relative rounded-b-xl" />
                <img
                  className="absolute bottom-0"
                  src={bg[index]}
                  preview={false}
                  style={{ objectFit: 'cover' }}
                />{' '}
                <img
                  className="h-[90px] rounded-b-xl"
                  src={bg_top}
                  preview={false}
                  style={{ objectFit: 'cover' }}
                />{' '}
              </div>
            </>
          );
        })}
      </Carousel>
    </>
  );
};

export default TopEvent;
