import CheckboxField from 'src/components/Form/Checkbox/checkboxField';
import PriceInputField from 'src/components/Form/CustomPriceInput/priceInputField';
import { ErrorMessageText } from 'src/components/Form/Input/styled';
import Hint from 'src/components/Hint';
import { getErrorMessage } from 'src/utils/formik';
import { capitalizeFirstLetter } from 'src/utils/string';
import { EarlyBirdOption } from 'src/utils/type';

const PriceTableItem = ({ name, label, formik, hint, disabledEarlyBird, onlyPreview }) => {
  const checkBoxName = `is_` + name;
  const { values } = formik;
  const isEnabledEarlyBird = values?.earlyBird === EarlyBirdOption.ENABLED;
  const earlyBirdPriceFieldName = `earlyBird` + capitalizeFirstLetter(name);

  const columnClassName = disabledEarlyBird ? 'col-span-6' : 'col-span-4';

  console.log('values', values);
  console.log('isEnabledEarlyBird', isEnabledEarlyBird);

  return (
    <div className="grid grid-cols-12 gap-4 pb-2 mt-3 content">
      <div className={`flex items-center gap-1 ${columnClassName}`}>
        <CheckboxField name={checkBoxName} label={label} className="" />
        <div className="z-50 mt-[10px] hidden scale-[0.8] md:block">
          {hint && <Hint message={hint} position={'right'} color="gray" />}
        </div>
      </div>
      <div className={`relative ${columnClassName}`}>
        <PriceInputField
          type="text"
          name={name}
          className={`form-input !pr-[15px] ${values[checkBoxName] ? 'bg-white' : 'bg-gray-200'} `}
          disabled={!values[checkBoxName] || onlyPreview}
          placeholder={!values[checkBoxName] ? '' : '.000'}
          bgColor={values[checkBoxName] ? ' || bg-white' : 'bg-gray-200'}
        />
        <ErrorMessageText>{getErrorMessage(name, formik)}</ErrorMessageText>
        <span className="absolute right-[15px] top-[10px] text-xs text-slate-500 md:top-[6px]">
          đ
        </span>
      </div>
      {!disabledEarlyBird && (
        <div className={`relative ${columnClassName}`}>
          <PriceInputField
            type="text"
            name={earlyBirdPriceFieldName}
            className={`form-input !pr-[15px] ${
              values[checkBoxName] && isEnabledEarlyBird ? 'bg-white' : 'bg-gray-200'
            } `}
            disabled={!values[checkBoxName] || !isEnabledEarlyBird || onlyPreview}
            placeholder={!values[checkBoxName] || !isEnabledEarlyBird ? '' : '.000'}
            bgColor={values[checkBoxName] && isEnabledEarlyBird ? 'bg-white' : 'bg-gray-200'}
          />
          <ErrorMessageText>{getErrorMessage(earlyBirdPriceFieldName, formik)}</ErrorMessageText>
          <span className="absolute right-[15px] top-[10px] text-xs text-slate-500 md:top-[6px]">
            đ
          </span>
        </div>
      )}
    </div>
  );
};

export default PriceTableItem;
