import { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

export default function InputCurrency({ onChange, defaultValue, bgColor, disable }) {
  const [reRender, setReRender] = useState();
  const value = defaultValue ? Number(defaultValue) / 1000 : undefined;
  const inputRef = useRef();
  const calcWidth = value => {
    if (!value || !inputRef.current) {
      return;
    }
    const widthElement = document?.getElementById('getWidthLength');
    widthElement.innerText = value + (value.length < 7 ? '.' : '0');
    inputRef.current.style.width = widthElement.offsetWidth + 'px';
  };

  const focusInput = () => {
    if (!value || !inputRef?.current) {
      return;
    }

    inputRef?.current?.focus();
  };

  useEffect(() => {
    calcWidth(value + '' || 'xxxx');
  }, [defaultValue, inputRef]);

  useEffect(() => {
    if (!!defaultValue) {
      setReRender(Math.random());
      focusInput();
    }
  }, [defaultValue]);

  const handleChangeCurrency = value => {
    if (value === undefined) {
      onChange(null);
    } else {
      onChange(Number(value) * 1000);
    }
  };

  // ? Because the width of the input is calculated based on the length of the value,
  // ? so we need to calculate the width of the input based on the length of the value
  // ? Sorry because the code is not clean
  // ? Step 1: Convert value to array
  const counts = {};
  const valueString = value?.toString()?.split('');
  // ? Step 2: Count the number of 1s in the array
  valueString?.forEach(function (x) {
    counts[x] = (counts[x] || 0) + 1;
  });
  // ? Step 3: Calculate the width of the input based on the number of 1s
  const lengthValue = value?.toString().length;
  const lengthNum = Number(lengthValue);
  const countOne = counts['1'] ? Number(counts['1']) : 0;
  const widthVal =
    lengthNum < 4
      ? (lengthNum - countOne) * 10 + countOne * 6.5
      : (lengthNum - countOne) * 11 + countOne * 6.5;

  return (
    <>
      <div className="flex flex-row items-center w-full" onClick={focusInput}>
        <CurrencyInput
          autoFocus
          disabled={disable}
          className={bgColor}
          placeholder=".000"
          value={!!value ? value : ''}
          ref={inputRef}
          maxLength={10}
          decimalSeparator=","
          groupSeparator="."
          onValueChange={handleChangeCurrency}
          key={reRender}
          style={{
            minWidth: lengthValue <= 3 ? '10px' : '33px',
            maxWidth: lengthValue < 2 ? '10px' : widthVal + 'px',
            width: 'unset!important'
          }}
        />
        {!!value && (
          <p
            className="text-gray-500"
            style={{
              lineHeight: '1'
            }}
          >
            .000
          </p>
        )}
      </div>
      <p id="getWidthLength" className="fixed bottom-0 opacity-0 w-fit" />
    </>
  );
}
