import "antd/dist/antd.min.css";
import React, {Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Loading from "./components/Loading/Loading";
import "./global.less";
import DefaultLayout from "./layout/DefaultLayout";
import Page404 from "./pages/page404";
import {AbilityContext} from "./casl/Abilities";
import {Ability} from "@casl/ability";

const ability = new Ability();


const LoginPage = React.lazy(() => import("src/pages/login"));

function App() {
    return (
        <div className="bg-white-theme">
            <AbilityContext.Provider value={ability}>
                <BrowserRouter>
                    <Suspense fallback={<Loading/>}>
                        <Routes>
                            <Route exact path="/login" element={<LoginPage/>}/>
                            <Route exact path="/404" name="Page 404" element={<Page404/>}/>
                            <Route exact path="*" element={<DefaultLayout ability={ability}/>}/>
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </AbilityContext.Provider>
        </div>
    );
}

export default App;
