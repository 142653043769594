import { useField, useFormikContext } from 'formik';
import DragDropUpload from './dragdropUpload';

export default function DragDropUploadField(props) {
  const { label, onChange } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);

  return (
    <DragDropUpload
      disabled={props?.disabled}
      className={props?.className}
      value={field.value}
      onChange={media => {
        console.log('media', media);
        setFieldTouched(field.name, true);
        setFieldValue(field.name, media);
        onChange && onChange(media);
      }}
      label={label}
    />
  );
}
