import React from "react";

const LogoSvg = () => {
  return (
    <span>
      <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.5673 4.04883C36.5673 4.04883 38.7465 22.5057 33.1016 29.9363C27.4567 37.3669 19.1468 38.5964 13.4494 34.1567C7.75198 29.7169 6.81992 21.2828 12.4714 13.8522C18.1163 6.42821 36.5673 4.04883 36.5673 4.04883Z"
          fill="url(#paint0_linear_1011_21560)"
        />
        <path
          d="M0.564586 6.49414C0.564586 6.49414 17.0924 7.80347 22.7307 14.6625C28.3691 21.5281 27.3386 29.3243 22.0744 33.7507C16.8101 38.1838 9.30765 37.5657 3.66929 30.7067C-1.9625 23.8477 0.564586 6.49414 0.564586 6.49414Z"
          fill="url(#paint1_linear_1011_21560)"
        />
        <path
          d="M12.4648 13.8581C6.81993 21.2887 7.74543 29.7229 13.4429 34.1626C14.6572 35.1064 15.9896 35.7976 17.3943 36.223C19.0681 35.7843 20.6565 34.9602 22.0743 33.7638C27.3386 29.3307 28.3625 21.5346 22.7242 14.6756C21.2473 12.8744 19.0221 11.4654 16.541 10.3555C14.9 11.3657 13.4757 12.5288 12.4648 13.8581Z"
          fill="url(#paint2_linear_1011_21560)"
        />
        <path
          d="M12.8324 2.71895C12.2876 4.65968 13.3969 6.68681 15.3201 7.23846C17.2368 7.7901 19.2388 6.66687 19.7836 4.7195C20.3284 2.77877 19.2191 0.751642 17.2959 0.199996C15.3792 -0.345002 13.3838 0.778227 12.8324 2.71895Z"
          fill="url(#paint3_linear_1011_21560)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1011_21560"
            x1="34.7096"
            y1="6.88827"
            x2="15.1421"
            y2="30.9062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF7500" />
            <stop offset="1" stopColor="#FF00FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1011_21560"
            x1="16.8407"
            y1="30.9338"
            x2="2.31501"
            y2="-2.81325"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0012A8" />
            <stop offset="1" stopColor="#FF00FF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1011_21560"
            x1="20.0701"
            y1="30.5537"
            x2="12.9236"
            y2="12.9091"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#88008A" />
            <stop offset="0.394" stopColor="#BD0076" />
            <stop offset="1" stopColor="#FF7500" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1011_21560"
            x1="19.7828"
            y1="4.72217"
            x2="12.8223"
            y2="2.76762"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EA7B00" />
            <stop offset="1" stopColor="#FFCB00" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

export default LogoSvg;
