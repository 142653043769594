const DashboardIcon = (props) => (
  <span {...props} role="img">
    <svg
      width="1.35rem"
      height="1.35rem"
      viewBox="0 0 24 24"
      fill="none"
      aria-hidden="true"
    >
      <path
        d="M19.1998 14.4H14.3998C13.5161 14.4 12.7998 15.1163 12.7998 16V20.8C12.7998 21.6836 13.5161 22.4 14.3998 22.4H19.1998C20.0835 22.4 20.7998 21.6836 20.7998 20.8V16C20.7998 15.1163 20.0835 14.4 19.1998 14.4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M7.99961 14.4H3.19961C2.31595 14.4 1.59961 15.1163 1.59961 16V20.8C1.59961 21.6836 2.31595 22.4 3.19961 22.4H7.99961C8.88327 22.4 9.59961 21.6836 9.59961 20.8V16C9.59961 15.1163 8.88327 14.4 7.99961 14.4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M7.99961 2.39996H3.19961C2.31595 2.39996 1.59961 3.11631 1.59961 3.99996V8.79996C1.59961 9.68362 2.31595 10.4 3.19961 10.4H7.99961C8.88327 10.4 9.59961 9.68362 9.59961 8.79996V3.99996C9.59961 3.11631 8.88327 2.39996 7.99961 2.39996Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M19.1998 2.39996H14.3998C13.5161 2.39996 12.7998 3.11631 12.7998 3.99996V8.79996C12.7998 9.68362 13.5161 10.4 14.3998 10.4H19.1998C20.0835 10.4 20.7998 9.68362 20.7998 8.79996V3.99996C20.7998 3.11631 20.0835 2.39996 19.1998 2.39996Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  </span>
);

export default DashboardIcon;
