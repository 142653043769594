import { gql } from '@apollo/client';
import { MEDIA_FRAGMENT } from './fragment';

export const BROADCAST_MUTATION = {
  ADD_SERIES: gql`
    mutation addSeriesEvent($input: AddSeriesEventInput!) {
      series {
        addSeriesEvent(input: $input) {
          success
        }
      }
    }
  `,
  UPDATE_EVENT_SERIES: gql`
    mutation updateEventSeries($input: UpdateEventSeriesInput!) {
      series {
        updateEventSeries(input: $input) {
          _id
        }
      }
    }
  `,
  DELETE_EVENT_SERIES: gql`
    mutation deleteEventSeries($input: DeleteEventSeriesInput!) {
      series {
        deleteEventSeries(input: $input) {
          success
        }
      }
    }
  `,
  CREATE_EVENT_SERIES: gql`
    mutation createEventSeries($input: CreateEventSeriesCmsInput!) {
      series {
        createEventSeries(input: $input) {
          _id
          name
        }
      }
    }
  `,
  APPROVE_SERI: gql`
    mutation approvedEventSeries($input: ApprovedEventSeriesInput!) {
      approvedEventSeries(input: $input) {
        success
      }
    }
  `
};

export const EVENT_MUTATION = {
  CREATE_EVENT: gql`
    mutation createEvent($input: EventRequest!) {
      event {
        createEvent(input: $input) {
          _id
        }
      }
    }
  `,
  UPDATE_EVENT: gql`
    mutation updateEvent($input: UpdateEventInput!) {
      event {
        updateEvent(input: $input) {
          _id
        }
      }
    }
  `,
  VERIFY_EVENT: gql`
    mutation verifyEvent($input: VerifyEventInput!) {
      event {
        verifyEvent(input: $input) {
          success
        }
      }
    }
  `,
  REJECT_EVENT: gql`
    mutation rejectEvent($input: RejectEventInput!) {
      event {
        rejectEvent(input: $input) {
          success
        }
      }
    }
  `,
  DELETE_EVENT: gql`
    mutation deleteEvent($input: DeleteEventInput!) {
      event {
        deleteEvent(input: $input) {
          success
        }
      }
    }
  `,
  RECEIVER_EVENT: gql`
    mutation eventReceiver($input: EventReceiverInput!) {
      event {
        eventReceiver(input: $input) {
          success
        }
      }
    }
  `,
  START_LIVE: gql`
    mutation startLiveStreamCms($input: GetPublicEventInputDto!) {
      startLiveStreamCms(input: $input) {
        success
      }
    }
  `,
  END_LIVE: gql`
    mutation endLiveStreamCms($input: GetPublicEventInputDto!) {
      endLiveStreamCms(input: $input) {
        success
      }
    }
  `,
  APPROVE_COMMENT: gql`
    mutation activeOrDeactiveConversation($input: ReadConversationInput!) {
      activeOrDeactiveConversation(input: $input) {
        success
      }
    }
  `,
  UPDATE_NOTE: gql`
    mutation updateNoteEvent($input: UpdateNoteEventInput!) {
      event {
        updateNoteEvent(input: $input) {
          success
        }
      }
    }
  `
};

export const EVENT_QUERY = {
  DETAIL_SERI: gql`
    ${MEDIA_FRAGMENT}
    query getEventSeries($input: GetEventSeriesInput!) {
      getEventSeries(input: $input) {
        _id
        name
        description
        startTime
        isApproved
        seriesNumber
        category {
          name
        }
        subCategory {
          name
        }
        thumbnail {
          ...MediaFragment
        }
        video {
          ...MediaFragment
        }
        price
        priceDay
        priceMonth
        event {
          _id
          name
          status
          type
          startTime
          venue
          description
          page {
            _id
            displayName
          }
        }
      }
    }
  `
};
