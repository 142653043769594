import React from "react";
import Loading from "src/components/Loading/Loading";
const Page404 = () => {
  return (
    <div>
      <div>Page404</div>
      <Loading />
    </div>
  );
};

export default Page404;
