import { useField, useFormikContext } from 'formik';
import FileUpload from './fileUpload';

export default function FileUploadField(props) {
  const { shouldLoadInBackground, onChange } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);

  return (
    <FileUpload
      disabled={props?.disabled}
      value={field.value}
      shouldLoadInBackground={shouldLoadInBackground}
      onChange={media => {
        setFieldTouched(field.name, true);
        setFieldValue(field.name, media);
        onChange && onChange(media);
      }}
    />
  );
}
