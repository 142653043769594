import { useEffect, useMemo, useRef, useState } from 'react';
import useOnClickOutside from 'src/hooks/useOnClickOutSide';
import { ArrowIcon as ArrowIconStyle, DropdownWrapper, OptionItem, OptionList } from './styled';

export const ArrowIcon = ArrowIconStyle;

export default function Dropdown(props) {
  const { options, value, onChange, placeholder, disabled = false } = props;
  const [selectedOption, setSelectedOption] = useState();
  const [isShowOption, setIsShowOption] = useState(false);

  useEffect(() => {
    if (value) {
      const option = options?.find(op => op?.value === value);
      if (option) {
        setSelectedOption(option);
      }
    } else {
      setSelectedOption(undefined);
    }
  }, [value, options]);

  const handleSelectOption = option => {
    setSelectedOption(option);
    setIsShowOption(false);
    onChange(option);
  };

  const ref = useRef(null);
  const renderOptions = useMemo(() => {
    if (!isShowOption) {
      return null;
    }
    return (
      <OptionList ref={ref}>
        {options?.map(option => (
          <OptionItem
            key={option.value}
            selected={option.value === selectedOption?.value}
            onClick={() => handleSelectOption(option)}
          >
            {option?.label || ''}
          </OptionItem>
        ))}
      </OptionList>
    );
  }, [isShowOption, options, selectedOption?.value, handleSelectOption]);

  useOnClickOutside(ref, () => {
    setIsShowOption(false);
  });

  return (
    <DropdownWrapper disabled={disabled} className={props?.className + ' text-sm'}>
      <div className="selectedOption" onClick={() => setIsShowOption(pre => !pre)}>
        {selectedOption?.label ? (
          <p className="label">{selectedOption?.label}</p>
        ) : (
          <p className="text-gray-400">{placeholder || 'Vui lòng chọn'}</p>
        )}
        <ArrowIcon isOpen={isShowOption} />
      </div>
      {renderOptions}
    </DropdownWrapper>
  );
}
