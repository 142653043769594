import HintIcon from '../Icons/hint';

export let Position;
(function (Position) {
  Position['TOP'] = 'top';
  Position['RIGHT'] = 'right';
  Position['BOTTOM'] = 'bottom';
  Position['LEFT'] = 'left';
})(Position || (Position = {}));

const Hint = ({ message, width, height, position, color, className }) => {
  const CLASSES_BY_POSITION = {
    [Position.TOP]: 'top-0',
    [Position.RIGHT]: 'top-[-50%] left-6',
    [Position.LEFT]: 'top-5 right-5',
    [Position.BOTTOM]: 'bottom-0 left-6'
  };

  // @ts-ignore
  const positionClass = CLASSES_BY_POSITION[position];

  return (
    <div className={`hover group relative z-20 inline-block ${className}`}>
      <HintIcon width={width || 25} height={height || 25} color={color} className="z-10" />
      <div
        className={`${positionClass} invisible absolute z-10 break-words rounded bg-white  p-2 text-sm font-light text-black drop-shadow-2xl transition-all group-hover:!visible`}
        style={{
          minWidth: '250px',
          width: 'fit-content',
          maxWidth: 800,
          zIndex: 9999
        }}
      >
        {message}
      </div>
    </div>
  );
};

export default Hint;
