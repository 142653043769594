import dayjs from 'dayjs';
import { convertToISODate } from './date_helper';
import { capitalizeFirstLetter } from './string';
import { EarlyBirdOption, EventPaymentType } from './type';

export const derivedEarlyBirdFormInput = values => {
  const {
    earlyBird,
    expiredEarlyBird,
    isCountEarlyBird,
    countEarlyBird,
    isExpiredEarlyBird,
    earlyBirdPriceDay,
    earlyBirdPriceMonth,
    earlyBirdPrice,
    is_price,
    is_priceMonth,
    is_priceDay,
    price
  } = values;

  const isEnabledEarlyBird = earlyBird === EarlyBirdOption.ENABLED;

  return {
    isExpiredEarlyBird,
    isCountEarlyBird,
    expiredEarlyBird: isExpiredEarlyBird && isEnabledEarlyBird ? expiredEarlyBird : undefined,
    countEarlyBird: isCountEarlyBird && isEnabledEarlyBird ? countEarlyBird : undefined,
    isEarlyBird: isEnabledEarlyBird,
    earlyBird,
    earlyBirdPriceDay: is_priceDay && isEnabledEarlyBird ? earlyBirdPriceDay : undefined,
    earlyBirdPriceMonth: is_priceMonth && isEnabledEarlyBird ? earlyBirdPriceMonth : undefined,
    earlyBirdPrice: price && isEnabledEarlyBird ? earlyBirdPrice : undefined
  };
};

export const derivedEarlyBirdPriceInput = values => {
  const formInputs = derivedEarlyBirdFormInput(values);

  const {
    isEarlyBird,
    earlyBirdPrice,
    earlyBirdPriceMonth,
    earlyBirdPriceDay,
    expiredEarlyBird,
    countEarlyBird
  } = formInputs;

  return {
    isEarlyBird,
    earlyBirdPriceDay: Number(earlyBirdPriceDay),
    earlyBirdPrice: Number(earlyBirdPrice),
    earlyBirdPriceMonth: Number(earlyBirdPriceMonth),
    expiredEarlyBird: expiredEarlyBird ? convertToISODate(expiredEarlyBird) : null,
    countEarlyBird: Number(countEarlyBird)
  };
};

export const derivedEventPriceFormInput = eventDetail => {
  const { sellMethod, price, priceDay, priceMonth, is_price, is_priceDay, is_priceMonth } =
    eventDetail;

  let prices = {
    is_price,
    is_priceDay,
    is_priceMonth,
    price: is_price ? Number(price) : undefined,
    priceDay: is_priceDay ? Number(priceDay) : undefined,
    priceMonth: is_priceMonth ? Number(priceMonth) : undefined
  };

  // With sell method is free, auto clear price
  if (sellMethod === EventPaymentType.FREE) {
    prices = {};
  }
  return prices;
};

export const derivedEventPriceInput = eventDetail => {
  const { price, priceDay, priceMonth } = derivedEventPriceFormInput(eventDetail);
  return {
    price,
    priceDay,
    priceMonth
  };
};

export const derivedInitialEarlyBirdForm = eventDetail => {
  const {
    isEarlyBird,
    earlyBirdPriceDay,
    earlyBirdPrice,
    earlyBirdPriceMonth,
    expiredEarlyBird,
    countEarlyBird
  } = eventDetail || {};

  return {
    earlyBird: isEarlyBird ? EarlyBirdOption.ENABLED : EarlyBirdOption.DISABLED,
    isEarlyBird,
    isExpiredEarlyBird: !!expiredEarlyBird,
    isCountEarlyBird: !!countEarlyBird,
    earlyBirdPriceDay: earlyBirdPriceDay && Number(earlyBirdPriceDay),
    earlyBirdPrice: earlyBirdPrice && Number(earlyBirdPrice),
    earlyBirdPriceMonth: earlyBirdPriceMonth && Number(earlyBirdPriceMonth),
    expiredEarlyBird,
    countEarlyBird: countEarlyBird && Number(countEarlyBird)
  };
};

export const derivedInitialPriceForm = eventDetail => {
  return {
    priceDay: eventDetail?.priceDay,
    priceMonth: eventDetail?.priceMonth,
    price: eventDetail?.price,
    priceLimited: eventDetail?.priceLimited || undefined,
    is_priceDay: !!eventDetail?.priceDay,
    is_priceMonth: !!eventDetail?.priceMonth,
    is_price: !!eventDetail?.price,
    is_priceLimited: !!eventDetail?.priceLimited
  };
};

const checkEnabledEarlyBird = data => {
  if (!data?.isEarlyBird) {
    return false;
  }
  const now = dayjs();
  const expiredEarlyBird = data?.expiredEarlyBird ? dayjs(data?.expiredEarlyBird) : undefined;

  const isExpired = expiredEarlyBird ? expiredEarlyBird.isBefore(now) : false;
  const isOutOfStock = (data?.usedEarlyBird || 0) >= (data?.countEarlyBird || 0);
  return !isOutOfStock && !isExpired;
};

export const getPrice = (data, type) => {
  console.log('Data', data, type);

  const isEnabledEarlyBird = checkEnabledEarlyBird(data);
  const originalPrice = data[type];

  if (!isEnabledEarlyBird) {
    return {
      isEnabledEarlyBird: false,
      originalPrice: originalPrice,
      price: originalPrice
    };
  }

  const earlyBirdPriceField =
    data.type === EventType.Live ? 'earlyBirdPrice' : 'earlyBird' + capitalizeFirstLetter(type);
  const earlyBirdPrice = data?.[earlyBirdPriceField];
  return {
    isEnabledEarlyBird: true,
    originalPrice,
    price: earlyBirdPrice
  };
};
