import { Pagination } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <a>Trước</a>;
  }

  if (type === "next") {
    return <a>Sau</a>;
  }

  return originalElement;
};

const PaginationCommon = ({ total, currentPage, pageSize, itemName, onChangeCallback }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-6 flex justify-between pagination">
      <div className="flex items-center ">
        <div>
          <span>{t("pagination-total")}</span>
          <span className="pagination-total"> {total} </span>
          <span>{t(itemName)}</span>
        </div>
      </div>
      <Pagination
        total={total}
        current={currentPage}
        defaultPageSize={pageSize}
        pageSize={pageSize ? pageSize : 10}
        onChange={onChangeCallback}
        showSizeChanger={false}
        itemRender={itemRender}
      />
    </div>
  );
};

export default PaginationCommon;
