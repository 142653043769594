import { notification } from 'antd';
import React from 'react';
import './styles.less';

export const openNotification = (type, msg) => {
  const getColorNotification = () => {
    switch (type) {
      case 'success':
        return '#22bb33';
      case 'error':
        return '#ff4444';
      case 'warning':
        return '#ffbb33';
      case 'info':
        return '#5bc0de';
      default:
        return '#22bb33';
    }
  };

  notification.open({
    style: {
      height: 'max-content',
      backgroundColor: getColorNotification()
    },
    type: 'error',
    description: msg,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C0 5.373 5.373 0 12 0C18.627 0 24 5.373 24 12C24 18.627 18.627 24 12 24C5.373 24 0 18.627 0 12ZM11.147 17.267L18.707 9.707C19.098 9.316 19.098 8.684 18.707 8.293C18.316 7.902 17.684 7.902 17.293 8.293L10.44 15.146L7.694 12.4C7.303 12.009 6.671 12.009 6.28 12.4C5.889 12.791 5.889 13.423 6.28 13.814L9.733 17.267C9.92 17.455 10.175 17.56 10.44 17.56C10.705 17.56 10.959 17.455 11.147 17.267Z"
          fill="white"
        />
      </svg>
    ),
    duration: 2,
    closeIcon: <></>
  });
};
