import * as React from 'react';
const CloseIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 48 48" {...props}>
    <linearGradient
      id="a"
      x1={9.858}
      x2={38.142}
      y1={9.858}
      y2={38.142}
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#f44f5a" />
      <stop offset={0.443} stopColor="#ee3d4a" />
      <stop offset={1} stopColor="#e52030" />
    </linearGradient>
    <path
      fill="url(#a)"
      d="M44 24c0 11.045-8.955 20-20 20S4 35.045 4 24 12.955 4 24 4s20 8.955 20 20z"
    />
    <path
      d="M33.192 28.95 28.243 24l4.95-4.95a2 2 0 0 0 0-2.828l-1.414-1.414a2 2 0 0 0-2.828 0L24 19.757l-4.95-4.95a2 2 0 0 0-2.828 0l-1.414 1.414a2 2 0 0 0 0 2.828l4.95 4.95-4.95 4.95a2 2 0 0 0 0 2.828l1.414 1.414a2 2 0 0 0 2.828 0l4.95-4.95 4.95 4.95a2 2 0 0 0 2.828 0l1.414-1.414a1.998 1.998 0 0 0 0-2.827z"
      opacity={0.05}
    />
    <path
      d="M32.839 29.303 27.536 24l5.303-5.303a1.5 1.5 0 0 0 0-2.121l-1.414-1.414a1.5 1.5 0 0 0-2.121 0L24 20.464l-5.303-5.303a1.5 1.5 0 0 0-2.121 0l-1.414 1.414a1.5 1.5 0 0 0 0 2.121L20.464 24l-5.303 5.303a1.5 1.5 0 0 0 0 2.121l1.414 1.414a1.5 1.5 0 0 0 2.121 0L24 27.536l5.303 5.303a1.5 1.5 0 0 0 2.121 0l1.414-1.414a1.5 1.5 0 0 0 .001-2.122z"
      opacity={0.07}
    />
    <path
      fill="#fff"
      d="m31.071 15.515 1.414 1.414a.999.999 0 0 1 0 1.414L18.343 32.485a.999.999 0 0 1-1.414 0l-1.414-1.414a.999.999 0 0 1 0-1.414l14.142-14.142a.999.999 0 0 1 1.414 0z"
    />
    <path
      fill="#fff"
      d="m32.485 31.071-1.414 1.414a.999.999 0 0 1-1.414 0L15.515 18.343a.999.999 0 0 1 0-1.414l1.414-1.414a.999.999 0 0 1 1.414 0l14.142 14.142a.999.999 0 0 1 0 1.414z"
    />
  </svg>
);
export default CloseIcon;
