import * as React from 'react';

const HintIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <g fill={props?.color || 'white'} clipPath="url(#a)">
      <path d="M11 20.625a9.625 9.625 0 1 1 0-19.25 9.625 9.625 0 0 1 0 19.25ZM11 22a11 11 0 1 0 0-22 11 11 0 0 0 0 22Z" />
      <path d="M7.226 7.956a.326.326 0 0 0 .331.34h1.134c.19 0 .341-.156.366-.344.124-.902.743-1.56 1.845-1.56.944 0 1.807.472 1.807 1.606 0 .873-.514 1.275-1.327 1.885-.925.673-1.658 1.458-1.606 2.733l.004.298a.344.344 0 0 0 .344.338h1.115a.344.344 0 0 0 .344-.344v-.144c0-.987.375-1.274 1.389-2.043.837-.637 1.71-1.344 1.71-2.827 0-2.078-1.754-3.082-3.675-3.082-1.742 0-3.65.812-3.781 3.144Zm2.14 7.924c0 .733.585 1.274 1.39 1.274.837 0 1.413-.541 1.413-1.274 0-.76-.578-1.293-1.415-1.293-.803 0-1.387.534-1.387 1.293Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props?.color || 'white'} d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default HintIcon;
