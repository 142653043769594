import * as React from 'react';

const CheckedIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#4D4D4D"
        d="M2.75 0A2.75 2.75 0 0 0 0 2.75v16.5A2.75 2.75 0 0 0 2.75 22h16.5A2.75 2.75 0 0 0 22 19.25V2.75A2.75 2.75 0 0 0 19.25 0H2.75Zm13.791 6.834a1.031 1.031 0 0 1 .015 1.444l-5.489 6.86a1.032 1.032 0 0 1-1.485.028l-3.637-3.638a1.031 1.031 0 1 1 1.458-1.458l2.88 2.878 4.775-6.084a1.032 1.032 0 0 1 1.485-.03h-.002Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CheckedIcon;
