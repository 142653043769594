/* eslint-disable prefer-destructuring */
import { useCallback, useMemo, useRef, useState } from 'react';
import CloseIcon from 'src/components/Icons/close';
import PlusIcon from 'src/components/Icons/plus';
import { HandlerUploadFileApi } from 'src/helper';

const FileUpload = ({ value, onChange, shouldLoadInBackground, disabled }) => {
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const clearFileInput = useCallback(() => {
    if (inputRef?.current) {
      // @ts-ignore
      inputRef.current.value = null;
    }
  }, [inputRef]);

  const handleUpload = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      const file = event.target.files[0];
      if (!file) return;
      clearFileInput();

      if (shouldLoadInBackground) {
        onChange({
          percent: 0,
          name: file.name
        });
      }

      setLoading(true);

      HandlerUploadFileApi(file).then(event => {
        onChange(event);
        console.log('success');
        setLoading(false);
      });
    },
    [onChange, clearFileInput]
  );

  const content = useMemo(() => {
    if (!value) {
      return null;
    }
    return (
      <>
        <span className="fileName ml-2 line-clamp-1 text-[13px]">{value?.name || ''}</span>
        {!disabled && (
          <CloseIcon width={20} height={20} className="mr-2 cursor-pointer" onClick={handleClear} />
        )}
      </>
    );
  }, [value]);

  const progressBar = useMemo(() => {
    if (!loading) {
      return null;
    }
    return (
      <>
        <span className="percent inset-0 flex h-full w-[70%] items-center justify-center bg-blue-600 text-white">
          70%
        </span>
        {!disabled && (
          <CloseIcon width={20} height={20} className="mr-2 cursor-pointer" onClick={handleClear} />
        )}
      </>
    );
  }, [loading]);

  return (
    <div className="form-input cursor-pointer relative !grid  grid-cols-[110px_1fr] !p-0 md:grid-cols-[150px_1fr] bg-white">
      {
        <input
          disabled={disabled}
          type="file"
          ref={inputRef}
          onChange={handleUpload}
          accept="video/*"
          className="absolute inset-0 !h-full opacity-0"
        />
      }

      <div
        className={`${
          disabled && 'bg-gray-300'
        } btn rounded-0 flex cursor-pointer items-center gap-1 border-r-[1px] border-solid border-r-gray-300 text-sm px-2`}
      >
        <PlusIcon width={20} height={20} />
        <span>Chọn file</span>
      </div>
      <div
        className={`content relative flex items-center justify-between ${
          content && 'bg-slate-500 text-white'
        } ${disabled && '!bg-gray-300 !text-black'}`}
      >
        {loading ? progressBar : content}
      </div>
    </div>
  );
};

export default FileUpload;
