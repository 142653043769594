export const EarlyBirdOption = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};

export const EventPaymentType = {
  FREE: 'FREE',
  PURCHASE: 'PURCHASE',
  PURCHASE_ONLY_EVENT: 'PURCHASEONLYEVENT'
};

export const EventType = {
  LIVE: 'LIVE',
  BROADCAST: 'BROADCAST'
};

export const TypeVoucher = {
  CODE: 'CODE',
  COMBO: 'COMBO'
};

export const CouponCodeType = {
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT'
};

export const OrderItemType = {
  DIAMOND: 'DIAMOND',
  GOLD: 'GOLD',
  SILVER: 'SILVER',
  NONE: 'NONE'
};

export const UserVerifyType = {
  GROUPS: 'GROUPS',
  PERSONAL: 'PERSONAL'
};

export const EditType = {
  BANNER: 'BANNER',
  EVENT: 'EVENT'
};

export const OrderStatusType = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED'
};

export const EventStatusType = {
  PENDING: 'PENDING',
  RECEIVER: 'RECEIVER',
  APPROVED: 'APPROVED',
  REJECT: 'REJECT'
};

export const OverViewChartLabel = {
  ApplyingAds: 'APPLYING_ADS',
  Interaction: 'INTERACTION',
  Revenue: 'REVENUE',
  SoledTicket: 'SOLED_TICKET',
  EVENT: 'EVENT'
};

export const AdminType = {
  ROOT: 'ROOT',
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  ACCOUNTANT: 'ACCOUNTANT'
};

export const PredefinedRangeType = {
  THIS_WEEK: 'THIS_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  THIS_YEAR: 'THIS_YEAR',
  LAST_7_DAY: 'LAST_7_DAY',
  LAST_30_DAY: 'LAST_30_DAY',
  CUSTOM: 'CUSTOM'
};

export const AnalyticsMatchType = {
  EXACT: 'EXACT'
};

export const OverViewChartLabelType = {
  SOLED_TICKET: 'SOLED_TICKET',
  INTERACTION: 'INTERACTION',
  REVENUE: 'REVENUE',
  APPLYING_ADS: 'APPLYING_ADS'
};

export const EventTypeApply = {
  VDay: 'V_DAY',
  VLimited: 'V_LIMITED',
  VLive: 'V_LIVE',
  VMonth: 'V_MONTH'
};

export const VoucherStatus = {
  VApplying: 'V_APPLYING',
  VExpired: 'V_EXPIRED',
  VUpcoming: 'V_UPCOMING'
};

export const VerifyVoucherStatus =  {
  VAccept: 'V_ACCEPT',
  VPending: 'V_PENDING',
  VReject: 'V_REJECT'
}