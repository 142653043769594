import React from 'react';
import AppendPracticeToEvent from './pages/liveshows/create/broadcast/appendPractice';
import DetailOrder from './pages/transaction/DetailOrder/detailOrder';

const Login = React.lazy(() => import('./pages/login'));
const CreateVoucher = React.lazy(() => import('./pages/promotion/voucher/create/components'));
const ListVoucher = React.lazy(() => import('./pages/promotion/voucher/list/components'));
const ListCombo = React.lazy(() => import('./pages/promotion/combo/list/components'));
const CreateCombo = React.lazy(() => import('./pages/promotion/combo/create/components'));
const UpdateCombo = React.lazy(() => import('./pages/promotion/combo/update/components'));
const UpdateVoucher = React.lazy(() => import('./pages/promotion/voucher/update/components'));
const Dashboard = React.lazy(() => import('./pages/dashboard'));
const RevenuePage = React.lazy(() => import('./pages/revenue'));

const ProfilePage = React.lazy(() => import('./pages/profile'));

const Audiences = React.lazy(() => import('./pages/users/audience/ListAudiences'));
const AudienceDetail = React.lazy(() => import('./pages/users/audience/DetailAudience/tab'));
const AudienceForm = React.lazy(() => import('./pages/users/audience/FormAudience'));
const UpdateUser = React.lazy(() => import('./pages/users/audience/updateUser/updateUser'));
const UpdateVerifyInfo = React.lazy(() =>
  import('./pages/users/audience/FormAudience/updateVerifyInfo')
);
const CreateVerifyInfo = React.lazy(() =>
  import('./pages/users/audience/FormAudience/updateVerifyInfo')
);

const Administrators = React.lazy(() => import('./pages/users/administrator/ListAdministrators'));
const ListPageManageByAdmin = React.lazy(() => import('./pages/users/administrator/ManagerPage'));
const AdministratorsForm = React.lazy(() =>
  import('./pages/users/administrator/FormAdministrator')
);
const CataLogs = React.lazy(() => import('./pages/catalog/ListCatalogs'));
const CatalogForm = React.lazy(() =>
  import('./pages/catalog/FormCatalog/createUpdateCategoryForm')
);
const CreateUpdateCategory = React.lazy(() => import('./pages/catalog/FormCatalog/index'));

const Distributors = React.lazy(() => import('./pages/distributor/ListDistributors'));

// const Artist = React.lazy(() => import('./pages/users_draft/Artist'));
// const ArtistForm = React.lazy(() => import('./pages/users_draft/Artist/Form'));
// const ArtistDetail = React.lazy(() => import('./pages/users_draft/Artist/Detail'));

const Producer = React.lazy(() => import('./pages/users/producers'));
const CreatePage = React.lazy(() => import('./pages/users/producers/CreateUpdatePage/createPage'));
const UpdatePage = React.lazy(() => import('./pages/users/producers/CreateUpdatePage/updatePage'));
const ProducerDetail = React.lazy(() => import('./pages/users/producers/Detail'));

const Items = React.lazy(() => import('./pages/gift/item/ListItems'));
const FormItems = React.lazy(() => import('./pages/gift/item/FormItem'));

const ShareRate = React.lazy(() => import('./pages/gift/shareRate/ListShareRate'));
const ShareRateDetail = React.lazy(() => import('./pages/gift/shareRate/FormShareRate'));

const Transactions = React.lazy(() => import('./pages/transaction/ListTransactions'));

const LiveShows = React.lazy(() => import('./pages/liveshows'));
const LiveShowDetail = React.lazy(() => import('./pages/liveshows/detail'));
const DetailSeri = React.lazy(() => import('./pages/liveshows/detail/detailSeri'));
const EditPractice = React.lazy(() => import('./pages/liveshows/edit/editPractice'));
const EditEvent = React.lazy(() => import('./pages/liveshows/edit/editEvent'));
const CreateEvent = React.lazy(() => import('./pages/liveshows/create/index'));
const Banner = React.lazy(() => import('./pages/banner'));
const CreateBanner = React.lazy(() => import('./pages/banner/create/index'));

const ConfigMenu = React.lazy(() => import('./pages/setting/settingMenu'));

const Tickets = React.lazy(() => import('./pages/ticket'));
const Language = React.lazy(() => import('./pages/language'));
const TicketDetail = React.lazy(() => import('./pages/ticket/detail'));
const Accountant = React.lazy(() => import('./pages/accountant'));
const DetailReportPage = React.lazy(() => import('./pages/accountant/childPages'));

/**
 * routes in AppContent
 * @path for Route path
 * Use 'detail' for page detail with id, eg: /nguoi-dung/nghe-si/detail/:id
 * Use 'edit' for page edit with id, /nguoi-dung/nghe-si/edit/:id
 * Use 'add' for page add new data with id, /nguoi-dung/nghe-si/add/:id
 * NOTE: If page create new data without id, please use "create", /nguoi-dung/nghe-si/create
 *
 * @name for display name in the header
 * @element for Elemnt of Route
 */
const routes = [
  { path: '/login', name: 'head-login', element: <Login /> },
  { path: '/analytics', name: 'head-analytics', element: <Dashboard /> },
  { path: '/revenue', name: 'head-analytics', element: <RevenuePage /> },
  {
    path: '/language',
    name: 'title-config',
    element: <Language />
  },
  {
    path: '/users/audiences',
    name: 'head-manage-account',
    element: <Audiences />
  },
  {
    path: '/users/audiences/detail/:id',
    name: 'head-detail-khan-gia',
    element: <AudienceDetail />
  },
  {
    path: '/users/audiences/verifyInfo/edit/:id',
    name: 'head-edit-khan-gia',
    element: <UpdateVerifyInfo />
  },
  {
    path: '/users/audiences/verifyInfo/create/:id',
    name: 'head-edit-khan-gia',
    element: <CreateVerifyInfo />
  },
  {
    path: '/users/audiences/createAudience',
    name: 'head-create-khan-gia',
    element: <AudienceForm mode={1} />
  },
  {
    path: '/users/audiences/edit/:id',
    name: 'head-edit-khan-gia',
    element: <UpdateUser />
  },

  {
    path: '/users/producers',
    name: 'head-list-nha-san-xuat',
    element: <Producer />
  },
  {
    path: '/pages/detail/:pageId',
    name: 'head-detail-nha-san-xuat',
    element: <ProducerDetail />
  },
  {
    path: '/users/producers/:id/createProducer',
    name: 'head-create-nha-san-xuat',
    //mode = 1 Form CREATE
    element: <CreatePage />
  },
  {
    path: '/users/producers/edit/:pageId',
    name: 'head-edit-nha-san-xuat',
    //mode = 2 Form EDIT
    element: <UpdatePage />
  },

  {
    path: '/users/administrators',
    name: 'head-manage-account',
    element: <Administrators />
  },
  {
    path: '/users/administrators/managePage/:id',
    name: 'head-create-quan-li-trang',
    element: <ListPageManageByAdmin />
  },
  {
    path: '/users/my-page',
    name: 'head-create-quan-li-trang',
    element: <ListPageManageByAdmin />
  },
  {
    path: '/users/administrators/createAdministrator',
    name: 'head-create-quan-tri-vien',
    element: <AdministratorsForm mode={1} />
  },
  {
    path: '/users/administrators/edit/:id',
    name: 'head-edit-quan-tri-vien',
    element: <AdministratorsForm mode={2} />
  },
  {
    // path: "/content-catalog/catalogs",
    path: '/catalogs',
    name: 'head-config',
    element: <CataLogs />
  },
  {
    // path: "/content-catalog/catalogs",
    path: '/accountings',
    name: 'head-analytic-accountant',
    element: <Accountant />
  },
  {
    // path: "/content-catalog/catalogs",
    path: '/accountings/detail/:id',
    name: 'head-manage-accountant',
    element: <DetailReportPage />
  },
  {
    // path: "/content-catalog/catalogs/create",
    path: '/catalogs/createCatalog',
    name: 'head-create-danh-muc',
    element: (
      <CatalogForm
        mode={1}
        initialValues={{
          firstColor: '#7ED321',
          secondColor: '#4A90E2'
        }}
      />
    )
  },
  {
    path: '/catalogs/edit/:id',
    name: 'head-edit-danh-muc',
    //mode = 2 Form EDIT
    element: <CreateUpdateCategory />
  },
  // {
  //   path: '/distributors',
  //   name: 'head-list-kenh-phan-phoi',
  //   element: <Distributors />
  // },
  {
    path: '/gifts/items',
    name: 'head-list-vat-pham',
    element: <Items />
  },
  {
    path: '/gifts/items/createItems',
    name: 'head-list-vat-pham',
    element: <FormItems />
  },
  {
    path: '/gifts/items/editItems/:id',
    name: 'head-list-vat-pham',
    element: <FormItems mode={2} />
  },
  {
    path: '/gifts/shared-rate',
    name: 'head-list-ty-le-chia-se',
    element: <ShareRate />
  },
  {
    path: '/gifts/shared-rate/createShareRate',
    name: 'head-list-ty-le-chia-se',
    element: <ShareRateDetail mode={1} />
  },
  {
    path: '/gifts/shared-rate/editShareRate/:id',
    name: 'head-list-ty-le-chia-se',
    element: <ShareRateDetail mode={2} />
  },
  {
    path: '/transactions',
    name: 'head-list-analytic-transaction',
    element: <Transactions />
  },
  {
    path: '/transactions/detail/:id',
    name: 'head-list-giao-dich',
    element: <DetailOrder />
  },

  {
    path: '/liveshows',
    name: 'head-list-noi-dung',
    element: <LiveShows />
  },
  {
    path: '/liveshows/detail/:id',
    name: 'title-manage-event',
    element: <LiveShowDetail />
  },
  {
    path: '/liveshows/detail/seri/:id',
    name: 'title-manage-event',
    element: <DetailSeri />
  },
  {
    path: '/liveshows/detail/seri/edit/:id',
    name: 'head-detail-liveshows',
    element: <EditPractice />
  },
  {
    path: '/page/:pageId/createEvent',
    name: 'head-create-liveshow',
    element: <CreateEvent />
  },
  {
    path: '/liveshows/event/:id/newSeri',
    name: 'head-create-liveshow',
    element: <AppendPracticeToEvent />
  },
  {
    path: '/liveshows/edit/:id',
    name: 'head-create-liveshow',
    element: <EditEvent />
  },
  // {
  //   path: '/liveshows/createLiveshow/settingPrice',
  //   name: 'head-create-liveshow',
  //   element: <SettingPriceForm />
  // },

  {
    path: '/sliders',
    name: 'head-manage-ads',
    element: <Banner />
  },
  {
    path: '/voucher',
    name: 'head-manage-voucher',
    element: <ListVoucher />
  },
  {
    path: '/voucher/create',
    name: 'head-manage-voucher',
    element: <CreateVoucher />
  },
  {
    path: '/combo',
    name: 'head-manage-combo',
    element: <ListCombo />
  },
  {
    path: '/combo/create',
    name: 'head-manage-combo',
    element: <CreateCombo />
  },
  {
    path: '/voucher/update/:id',
    name: 'head-manage-combo',
    element: <UpdateVoucher />
  },
  {
    path: '/combo/update/:id',
    name: 'head-manage-combo',
    element: <UpdateCombo />
  },
  {
    path: '/banners',
    name: 'head-manage-ads',
    element: <CreateBanner />
  },

  {
    path: '/config/menu',
    name: 'head-config',
    element: <ConfigMenu />
  },

  // {
  //   path: '/tickets',
  //   name: 'head-ticket',
  //   element: <Tickets />
  // },
  {
    path: '/tickets/detail/:id',
    name: 'head-detail-ticket',
    element: <TicketDetail />
  },

  { path: '/profile', name: 'head-profile', element: <ProfilePage /> }
];

const convertPathNameForHeader = path => {
  //if detail page
  if (
    path.includes('detail') ||
    path.includes('edit') ||
    path.includes('managePage') ||
    path.includes('add')
  ) {
    const tempPath = path.slice(0, path.lastIndexOf('/'));
    return tempPath;
  }
  return path;
};

const mappingHeaderName = routes.reduce((acc, cur) => {
  const tempPath = convertPathNameForHeader(cur.path);
  acc[tempPath] = cur.name;
  return acc;
}, {});

export default routes;
export { convertPathNameForHeader, mappingHeaderName };
