import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertPathNameForHeader, mappingHeaderName } from 'src/routes';
import UserContent from './UserContent';

const Header = props => {
  const { open, setOpen } = props;
  const location = useLocation();
  const [curHeaderName, setCurHeaderName] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const tempPath = convertPathNameForHeader(location.pathname);
    setCurHeaderName(mappingHeaderName[tempPath]);
  }, [location]);

  console.log('curHeaderName', curHeaderName);

  return (
    <div className="sticky top-0 z-50 flex items-center justify-between w-full px-5 py-3 text-center border-b max-h-24 bg-white-header border-grey">
      <div className="flex items-center">
        <AiOutlineMenuUnfold
          onClick={() => setOpen(!open)}
          className={`bg-white text-dark-purple text-xl cursor-pointer ${open && 'rotate-180'}`}
        />
        <div className="ml-2 text-2xl font-bold uppercase cursor-pointer">{t(curHeaderName)}</div>
      </div>
      <div className="flex items-center justify-between">
        {/* <LocaleContent /> */}
        <UserContent />
      </div>
    </div>
  );
};

export default Header;
