import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { OverViewChartLabel } from 'src/utils/type';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

let delayed;

export const options = {
  responsive: true,
  animations: {
    onComplete: () => {
      delayed = true;
    },
    delay: context => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    }
  },
  plugins: {
    legend: {
      onClick: null,
      position: 'bottom'
    }
  },
  scales: {
    y3: {
      display: false,
      ticks: {
        display: false,
        beginAtZero: true
      }
    },
    y: {
      display: false,
      ticks: {
        display: false,
        beginAtZero: true
      }
    },
    y2: {
      display: false,
      ticks: {
        display: false,
        beginAtZero: true
      }
    },
    y1: {
      display: false,
      ticks: {
        display: false,
        beginAtZero: true
      }
    }
  }
};

const getChartConfig = (type, selectedAnalytics) => {
  const isToggleLegend = type => {
    return selectedAnalytics?.includes(type) ? true : false;
  };

  switch (type) {
    case OverViewChartLabel.SoledTicket:
      return {
        label: isToggleLegend(OverViewChartLabel.SoledTicket) ? 'Số lượng vé đã bán' : '',
        borderColor: isToggleLegend(OverViewChartLabel.SoledTicket)
          ? 'rgba(16, 36, 159, 1)'
          : '#ffffff',
        backgroundColor: isToggleLegend(OverViewChartLabel.SoledTicket)
          ? 'rgba(16, 36, 159, 0.2)'
          : '#ffffff',
        fill: true,
        tension: 0.5,
        yAxisID: 'y'
        // hidden: !selectedAnalytics?.includes(OverViewChartLabel.SoledTicket)
      };
    case OverViewChartLabel.Interaction:
      return {
        label: isToggleLegend(OverViewChartLabel.Interaction) ? 'Luợt tương tác' : '',
        borderColor: isToggleLegend(OverViewChartLabel.Interaction)
          ? 'rgba(117, 20, 161, 1)'
          : '#ffffff',
        backgroundColor: isToggleLegend(OverViewChartLabel.Interaction)
          ? 'rgba(117, 20, 161, 0.2)'
          : '#ffffff',
        fill: true,
        tension: 0.5,
        yAxisID: 'y3'
        // hidden: !selectedAnalytics?.includes(OverViewChartLabel.Interaction)
      };
    case OverViewChartLabel.EVENT:
      return {
        label: isToggleLegend(OverViewChartLabel.EVENT) ? 'Tổng số Event' : '',
        borderColor: isToggleLegend(OverViewChartLabel.EVENT) ? 'rgba(220, 0, 0, 1)' : '#ffffff',
        backgroundColor: isToggleLegend(OverViewChartLabel.EVENT)
          ? 'rgba(220, 0, 0, 0.2)'
          : '#ffffff',
        fill: true,
        tension: 0.5,
        yAxisID: 'y2'
        // hidden: !selectedAnalytics?.includes(OverViewChartLabel.ApplyingAds)
      };
    case OverViewChartLabel.Revenue:
      return {
        label: isToggleLegend(OverViewChartLabel.Revenue) ? 'Doanh số' : '',
        borderColor: isToggleLegend(OverViewChartLabel.Revenue)
          ? 'rgba(253, 165, 41, 1)'
          : '#ffffff',
        backgroundColor: isToggleLegend(OverViewChartLabel.Revenue)
          ? 'rgba(253, 165, 41, 0.1)'
          : '#ffffff',
        fill: true,
        tension: 0.5,
        yAxisID: 'y1'
        // hidden:  !selectedAnalytics?.includes(OverViewChartLabel.Revenue)
      };
  }
};

export default function AnalyticChart({ data, selectedAnalytics }) {
  const dataWithoutAds = data?.datasets?.filter(
    item => item.label !== OverViewChartLabel.ApplyingAds
  );

  console.log('dataWithoutAds', dataWithoutAds);

  const datasets = (dataWithoutAds || [])?.map((item, index) => ({
    ...getChartConfig(item.label, selectedAnalytics),
    hidden: !selectedAnalytics?.includes(item.label),
    borderWidth: 1,
    data: item?.data || []
  }));

  console.log('datasets', data?.datasets);
  console.log('datasets', datasets);

  return (
    <Line
      options={options}
      data={{
        labels: data?.labels || [],
        datasets
      }}
      className="chart-plot-background"
    />
  );
}
