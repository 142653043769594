import { useQuery } from '@apollo/client';
import { DatePicker, Select, Skeleton } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { QUERY } from 'src/constants';
import { ADMIN_QUERY } from 'src/constants/admin.graphql';
import { PredefinedRangeOptions } from 'src/utils/common';
import { AdminType, AnalyticsMatchType, PredefinedRangeType } from 'src/utils/type';
import AnalyticChart from './analyticChart';
import useAnalyticManager from './hooks/useAnalyticManager';
import Overview from './overview';
import './styles.less';
import TopEvent from './topEvent';
const { RangePicker } = DatePicker;

export const localeVN = {
  lang: {
    locale: 'vi',
    rangePlaceholder: ['Ngày bắt đầu', 'Ngày kết thúc'],
    // month: [
    //   'Tháng 1',
    //   'Tháng 2',
    //   'Tháng 3',
    //   'Tháng 4',
    //   'Tháng 5',
    //   'Tháng 6',
    //   'Tháng 7',
    //   'Tháng 8',
    //   'Tháng 9',
    //   'Táng 10 ',
    //   'Tháng 11',
    //   'Tháng 12'
    // ],
    year: 'Năm',
    timeSelect: 'Chọn thời gian',
    dateSelect: 'Chọn ngày',
    weekSelect: 'Chọn tuần',
    shortWeekDays: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'],
    shortMonths: null,
    shortMonths: [
      'Tháng 1',
      'Tháng 2',
      'Tháng 3',
      'Tháng 4',
      'Tháng 5',
      'Tháng 6',
      'Tháng 7',
      'Tháng 8',
      'Tháng 9',
      'Tháng 10',
      'Tháng 11',
      'Tháng 12'
    ],
    yearFormat: '[Tháng] MM/YYYY'
  }
};

const Dashboard = () => {
  const {
    chart,
    overview,
    setInput,
    revenues,
    input,
    labelSelected,
    selectedAnalytics,
    setLabelSelected,
    setSelectedAnalytics,
    topEvents,
    percentage,
    totalViewNow,
    analyticLoading
  } = useAnalyticManager();

  // ? Maybe who join this project after me will not understand this code
  // ? I will explain it to you
  // ? BE and BA not clear about the requirement
  // ? So I have to do this
  // ? User choose this month, this week, this year, must be set start date and end date
  // ? User choose custom, must be set start date and end date
  const onChangeDate = (value, dateString) => {
    if (input?.predefinedRange === PredefinedRangeType.THIS_MONTH) {
      setInput({
        ...input,
        startDate: dayjs(dateString).startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs(dateString).endOf('month').format('YYYY-MM-DD')
      });
    }
    if (input?.predefinedRange === PredefinedRangeType.THIS_WEEK) {
      setInput({
        ...input,
        startDate: dayjs(dateString).startOf('week').format('YYYY-MM-DD'),
        endDate: dayjs(dateString).endOf('week').format('YYYY-MM-DD')
      });
    }
    if (input?.predefinedRange === PredefinedRangeType.THIS_YEAR) {
      setInput({
        ...input,
        startDate: dayjs(dateString).startOf('year').format('YYYY-MM-DD'),
        endDate: dayjs(dateString).endOf('year').format('YYYY-MM-DD')
      });
    }
    if (input?.predefinedRange === PredefinedRangeType.CUSTOM) {
      setInput({
        ...input,
        startDate: dateString[0],
        endDate: dateString[1]
      });
    }
  };

  const [pageId, setPageId] = useState('');

  const { data: listPageResponse, loading } = useQuery(QUERY.GET_LIST_PAGE_IN_SYSTEM, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        pagination: {
          limit: 999,
          page: 1
        },
        filter: {
          status_eq: 'PUBLIC'
        }
      }
    }
  });

  const listPage = useMemo(() => {
    return listPageResponse?.listPage?.data;
  }, [listPageResponse]);

  const filteredOptions = listPage?.map(page => ({
    label: page?.displayName,
    value: page?._id
  }));

  const user = JSON.parse(localStorage.getItem('userInfo'));
  console.log('user', user);

  const { data: myPageResponse } = useQuery(ADMIN_QUERY.LIST_PAGE_OF_ADMIN, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        adminId: user?._id
      }
    }
  });

  const userType = user.type;

  const myPages = useMemo(() => {
    return myPageResponse?.detailPagesOfAdmin?.data;
  }, [myPageResponse]);

  const dataPage = [];

  for (let index = 0; index < myPages?.length; index++) {
    const element = myPages[index];

    const pagesOfAdmin = element?.pagesOfAdmin.map(page => ({
      label: page?.displayName,
      value: page?._id
    }));

    dataPage.push(...pagesOfAdmin);
  }

  const onChangePageOptions = value => {
    setPageId(value);
    setInput({
      ...input,
      stringFilter: {
        matchType: AnalyticsMatchType.EXACT,
        value: '/page/' + value
      }
    });
  };

  if (analyticLoading) {
    return <Skeleton />;
  }

  return (
    <div className="text-[#4D4D4D]">
      <div className="z-20 flex items-center justify-between mb-4 header">
        <h2 className="text-xl font-semibold uppercase">Tổng quan</h2>

        {/* <Filter setInput={setInput} /> */}
        <div className="flex justify-end gap-3">
          <Select
            className="w-[200px]"
            showSearch
            placeholder="Nhà sản xuất"
            onChange={onChangePageOptions}
            allowClear
            options={userType === AdminType.ADMIN ? dataPage : filteredOptions}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())
            }
          />
          {/* <Select
            className="w-[200px]"
            placeholder="Tìm kiếm trang"
            showSearch
            value={pageId}
            options={filteredOptions}
            onChange={value => {
              setPageId(value);
              setInput({
                ...input,
                stringFilter: {
                  matchType: AnalyticsMatchType.EXACT,
                  value: '/page/' + value
                }
              });
            }}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())
            }
          /> */}
          <Select
            className="w-[200px]"
            options={PredefinedRangeOptions}
            defaultValue={input?.predefinedRange}
            onChange={opt => {
              if (
                opt === PredefinedRangeType?.LAST_30_DAY ||
                opt === PredefinedRangeType?.LAST_7_DAY ||
                opt === PredefinedRangeType?.CUSTOM
              ) {
                setInput({ ...input, predefinedRange: opt });
              }
              if (opt === PredefinedRangeType?.THIS_YEAR) {
                setInput({
                  ...input,
                  predefinedRange: opt,
                  startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
                  endDate: dayjs().endOf('year').format('YYYY-MM-DD')
                });
              }
              if (opt === PredefinedRangeType?.THIS_MONTH) {
                setInput({
                  ...input,
                  predefinedRange: opt,
                  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
                  endDate: dayjs().endOf('month').format('YYYY-MM-DD')
                });
              }
            }}
          />
          <div className="w-[300px]">
            {input?.predefinedRange === 'CUSTOM' && (
              <RangePicker
                className={`${input?.predefinedRange !== 'CUSTOM' && 'hidden'}`}
                onChange={onChangeDate}
                locale={localeVN}
              />
            )}
            {input?.predefinedRange === 'THIS_MONTH' && (
              <DatePicker
                onChange={onChangeDate}
                picker="month"
                locale={localeVN}
                defaultValue={dayjs().clone().startOf('month')}
              />
            )}
            {input?.predefinedRange === 'THIS_YEAR' && (
              <DatePicker
                onChange={onChangeDate}
                picker="year"
                locale={localeVN}
                defaultValue={dayjs().clone().startOf('year')}
              />
            )}
            {input?.predefinedRange === 'LAST_30_DAY' && (
              <RangePicker
                disabled
                onChange={onChangeDate}
                locale={localeVN}
                defaultValue={[moment().add(-30, 'day'), moment()]}
              />
            )}
            {input?.predefinedRange === 'LAST_7_DAY' && (
              <RangePicker
                disabled
                locale={localeVN}
                defaultValue={[moment().add(-7, 'day'), moment()]}
              />
            )}
          </div>
        </div>
      </div>

      <Overview
        data={overview}
        setSelectedAnalytics={setSelectedAnalytics}
        selectedAnalytics={selectedAnalytics}
        setLabelSelected={setLabelSelected}
        percentage={percentage}
        totalViewNow={totalViewNow}
        type={input?.predefinedRange}
      />

      <div className="chart-container">
        <h4 className="pb-2 my-2 text-lg font-bold uppercase">Biểu đồ</h4>
        <div className="p-3 bg-white shadow-2xl rounded-xl">
          <AnalyticChart data={chart} key={chart} selectedAnalytics={selectedAnalytics} />
        </div>
      </div>

      <div className="px-5 mt-10">
        <TopEvent topEvents={topEvents} />
      </div>
    </div>
  );
};

export default Dashboard;
