import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ANALYTICS_QUERY } from 'src/constants/analytics.graphql';

const useDetailOrder = () => {
  const { id } = useParams();

  const { data } = useQuery(ANALYTICS_QUERY.DETAIL_ORDER, {
    variables: {
      input: {
        orderId: id
      }
    },
    fetchPolicy: 'no-cache'
  });

  const detailOrder = useMemo(() => {
    return data?.detailOrder;
  }, [data]);

  return { detailOrder };
};

export default useDetailOrder;
