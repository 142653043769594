import { Tooltip } from 'antd';
import CheckedCircleBlue from 'src/components/Icons/blue_checked';
import CloseIcon from 'src/components/Icons/close_icon';
import CheckedGreenIcon from 'src/components/Icons/green_checked';
import warning_icon from '../assets/warning.png';
import {
  EventTypeApply,
  OrderItemType,
  OrderStatusType,
  PredefinedRangeType,
  VerifyVoucherStatus,
  VoucherStatus
} from './type';

export const userVerifyStatusOptions = [
  {
    value: 'ACCEPT',
    label: 'Đã xác thực',
    color: 'text-green-500'
  },
  {
    value: 'NOTSEND',
    label: 'Chưa gửi xác thực',
    color: 'text-gray-500'
  },
  {
    value: 'PENDING',
    label: 'Chờ xác thực',
    color: 'text-yellow-500'
  },
  {
    value: 'REJECT',
    label: 'Bị từ chối',
    color: 'text-red-500'
  }
];

export const userVerifyTypeOtions = [
  {
    value: 'PERSONAL',
    label: 'Cá nhân'
  },
  {
    value: 'GROUPS',
    label: 'Tổ chức'
  }
];

export const pageVerifyStatusOptions = [
  {
    value: 'UNPUBLIC',
    label: 'Chưa xác thực',
    color: 'text-gray-500'
  },
  {
    value: 'PUBLIC',
    label: 'Đã xác thực',
    color: 'text-green-500'
  },
  {
    value: 'DEACTIVE',
    label: 'Đã khóa',
    color: 'text-red-500'
  }
];

export const statusEventOptions = [
  {
    value: 'APPROVED',
    label: 'Đã duyệt',
    color: 'text-green-500'
  },
  {
    value: 'PENDING',
    label: 'Đang chờ duyệt',
    color: 'text-yellow-500'
  },
  {
    value: 'RECEIVER',
    label: 'Đã tiếp nhận',
    color: 'text-blue-500'
  },
  {
    value: 'REJECT',
    label: 'Từ chối duyệt',
    color: 'text-red-500'
  }
];

export const eventType = [
  {
    value: 'LIVE',
    label: 'Livestream'
  },
  {
    value: 'BROADCAST',
    label: 'Công chiếu'
  }
];

export const createPageOptions = [
  {
    value: 'INDIVIDUAL',
    label: 'Cá nhân'
  },
  {
    value: 'GROUP',
    label: 'Nhóm'
  },
  {
    value: 'ORGANIZATION',
    label: 'Tổ chức'
  }
];

export const eventTypeOptions = [
  {
    label: 'Livestream',
    value: 'LIVE'
  },
  {
    label: 'Công chiếu',
    value: 'BROADCAST'
  }
];

export const getLabelEvent = type => {
  switch (type) {
    case 'LIVE':
      return 'Livestream';
    case 'BROADCAST':
      return 'Công chiếu';
    default:
      return '';
  }
};

export const chooseEarlyBirdOptions = [
  {
    label: 'Áp dụng',
    value: true
  },
  {
    label: 'Không áp dụng',
    value: false
  }
];

export const paymentTypeOptionsLive = [
  {
    label: 'Miễn phí',
    value: 'FREE'
  },
  {
    label: 'Thu phí',
    value: 'PURCHASE'
  }
];

export const paymentTypeOptionsBroadcast = [
  {
    label: 'Miễn phí',
    value: 'FREE'
  },
  {
    label: 'Thu phí cả tập',
    value: 'PURCHASE'
  },
  {
    label: 'Thu phí từng tập',
    value: 'PURCHASEONLYEVENT'
  }
];

export const genderOptions = [
  {
    label: 'Không xác định',
    value: 'Unknown'
  },
  {
    label: 'Nam',
    value: 'Male'
  },
  {
    label: 'Nữ',
    value: 'Female'
  }
];

export const getTicketType = type => {
  switch (type) {
    case OrderItemType.DIAMOND:
      return 'Không giới hạn';
    case OrderItemType.GOLD:
      return '30 ngày';
    case OrderItemType.SILVER:
      return '48 giờ';
    case OrderItemType.NONE:
      return 'Livestream';
    default:
      return 'Vé tặng';
  }
};

export const getTicketTypeLabel = type => {
  switch (type) {
    case OrderItemType.DIAMOND:
      return {
        label: 'Không giới hạn',
        desc: `Xem Sự kiện Công chiếu. Không giới hạn thời gian xem và số lần xem.`
      };
    case OrderItemType.GOLD:
      return {
        label: '30 ngày',
        desc: `Xem Sự kiện Công chiếu. Thời gian xem là 30 ngày từ khi mua/hoặc từ khi sự kiện được công chiếu. Không giới hạn số lần xem.`
      };
    case OrderItemType.SILVER:
      return {
        label: '48 giờ',
        desc: `Xem Sự kiện Công chiếu. Thời gian kích hoạt là 30 ngày từ khi mua/hoặc từ khi sự kiện được công chiếu , và còn 48 giờ xem khi bắt đầu kích hoạt. Không giới hạn số lần xem.`
      };
    case OrderItemType.NONE:
      return {
        label: 'Livestream',
        desc: ` Xem Sự kiện Livestream 01 lần duy nhất tại thời điểm phát sóng trực tiếp.`
      };
    default:
      return {
        label: 'Vé tặng',
        desc: `Vé tặng Livestream có thời hạn trong 48 giờ`
      };
  }
};

const optionBannerItem = index => {
  return {
    options: [
      {
        label: 'Tự động',
        value: 'AUTO'
      },
      {
        label: 'Ghim',
        value: 'PIN'
      }
    ],
    key: index + 1
  };
};

export const bannerItemOptions = [
  optionBannerItem(0),
  optionBannerItem(1),
  optionBannerItem(2),
  optionBannerItem(3),
  optionBannerItem(4)
];

export const bannnerTypeOptions = index => {
  const options = [
    {
      label: 'Tự động',
      value: 'AUTO',
      key: index
    },
    {
      label: 'Ghim',
      value: 'PIN',
      key: index
    }
  ];
  return options;
};

export const editSliderOptions = [
  {
    label: 'Sự kiện',
    value: 'EVENT'
  },
  {
    label: 'Banner',
    value: 'BANNER'
  }
];

export const getLableStatusOrder = status => {
  switch (status) {
    case OrderStatusType.COMPLETED:
      return <span className="transaction-status transaction-status-success">{'Thành công'}</span>;
    case OrderStatusType.PENDING:
      return <span className="transaction-status transaction-status-pending">{'Đang chờ'}</span>;
    case OrderStatusType.FAILED:
      return <span className="transaction-status transaction-status-fail">{'Thất bại'}</span>;
  }
};

export const statusVerifyPage = status => {
  switch (status) {
    case 'VERIFIED':
      return <span className="transaction-status transaction-status-success">{'Đã xác thực'}</span>;
    case 'PENDING':
      return (
        <span className="transaction-status transaction-status-pending">{'Đang chờ xác thực'}</span>
      );
    case 'REOPEN':
      return <span className="text-black">{'Xác thực lại'}</span>;
    case 'REJECT':
      return (
        <span className="transaction-status transaction-status-fail">{'Từ chối xác thực'}</span>
      );
    default:
      return <span className="text-black">{'Chưa xác thực'}</span>;
  }
};

export const getLabelPaymentType = type => {
  switch (type) {
    case 'PURCHASE':
      return 'Thu phí';
    case 'FREE':
      return 'Miễn phí';
    case 'PURCHASEONLYEVENT':
      return 'Thu phí từng tập';
    default:
      return 'Miễn phí';
  }
};

export const adminRoleOptions = [
  {
    label: 'Kiểm duyệt viên',
    value: 'SUPERADMIN'
  },
  {
    label: 'Phụ trách khách hàng',
    value: 'ADMIN'
  },

  {
    label: 'Kế toán',
    value: 'ACCOUNTANT'
  }
];

export const getLabelAdminRole = role => {
  switch (role) {
    case 'SUPERADMIN':
      return (
        <>
          <span className="font-bold text-blue-700">Kiểm duyệt viên</span>
        </>
      );
    case 'ADMIN':
      return (
        <>
          <span className="font-bold text-blue-300">Phụ trách khách hàng</span>
        </>
      );
    case 'ACCOUNTANT':
      return (
        <>
          <span className="font-bold text-green-500">Kế toán</span>
        </>
      );
    default:
      return 'Admin';
  }
};

// export const PredefinedRangeType = {
//   THIS_WEEK: 'Trong 7 ngày qua',
//   THIS_MONTH: 'Trong 30 ngày qua',
//   THIS_YEAR: 'Theo Năm',
//   LAST_7_DAY: 'Trong 7 ngày qua',
//   LAST_30_DAY: 'Trong 30 ngày qua',
//   CUSTOM: 'CUSTOM'
// };

export const PredefinedRangeOptions = [
  // {
  //   value: 'THIS_WEEK',
  //   label: 'Trong tuần qua'
  // },
  {
    value: 'LAST_7_DAY',
    label: 'Trong 7 ngày qua'
  },
  {
    value: 'LAST_30_DAY',
    label: 'Trong 30 ngày qua'
  },
  {
    value: 'THIS_MONTH',
    label: 'Theo Tháng'
  },
  {
    value: 'THIS_YEAR',
    label: 'Theo Năm'
  },
  {
    value: 'CUSTOM',
    label: 'Tùy chọn'
  }
];

export const getLabelPredefinedRange = type => {
  switch (type) {
    case PredefinedRangeType?.LAST_30_DAY:
      return '30 ngày qua';
    case PredefinedRangeType?.LAST_7_DAY:
      return '7 ngày qua';
    case PredefinedRangeType?.THIS_YEAR:
      return 'năm qua';
    case PredefinedRangeType?.THIS_MONTH:
      return 'tháng qua';
    case PredefinedRangeType?.CUSTOM:
      return 'các ngày trước';
  }
};

export default function download(dataurl) {
  const link = document.createElement('a');
  link.href = dataurl;
  link.target = '_blank';
  link.click();
}

export const percentage = (totalValue, partialValue) => {
  const realValue = (totalValue * partialValue) / 100;
  return realValue.toFixed();
};

export const getLabelPageVerifyStatus = status => {
  switch (status) {
    case 'PENDING':
      return {
        label: 'Đang chờ xác thực (Xác thực ngay)',
        color: 'text-yellow-500'
      };
    case 'VERIFIED':
      return {
        label: 'Đã xác thực',
        color: 'text-green-500'
      };
    case 'REJECT':
      return {
        label: 'Đã từ chối',
        color: 'text-red-500'
      };
    case 'REOPEN':
      return {
        label: 'Xác thực lại',
        color: 'text-red-500'
      };
    default:
      return {
        label: 'Chưa xác thực',
        color: 'text-gray-500'
      };
  }
};

const receiverTitle = <span className="text-blue-300">Xác thực lại</span>;
const approvedTitle = <span className="text-green-500">Đã xác thực</span>;
const pendingTitle = <span className="text-yellow-500">Đang chờ xác thực (Xác thực ngay)</span>;
const rejectTitle = <span className="text-red-500">Đã từ chối</span>;

export const getIconStatusVerifyPage = status => {
  switch (status) {
    case 'REOPEN':
      return (
        <Tooltip title={receiverTitle}>
          <CheckedCircleBlue />
        </Tooltip>
      );
    case 'VERIFIED':
      return (
        <Tooltip title={approvedTitle}>
          <CheckedGreenIcon />
        </Tooltip>
      );
    case 'REJECT':
      return (
        <Tooltip title={rejectTitle}>
          <CloseIcon />
        </Tooltip>
      );
    case 'PENDING':
      return (
        <Tooltip title={pendingTitle}>
          <img src={warning_icon} />
        </Tooltip>
      );
    default:
      return 'Chưa xác thực';
  }
};

export const getLabelPageType = type => {
  switch (type) {
    case 'INDIVIDUAL':
      return 'Cá nhân';
    case 'GROUP':
      return 'Nhóm';
    case 'ORGANIZATION':
      return 'Tổ chức';
  }
};

export const getTicketField = type => {
  if (type?.includes('priceLimited')) {
    return EventTypeApply.VLimited;
  }
  if (type?.includes('priceDay')) {
    return EventTypeApply.VDay;
  }
  if (type?.includes('priceMonth')) {
    return EventTypeApply.VMonth;
  }
  if (type?.includes('price')) {
    return EventTypeApply.VLive;
  }
};

export const getPriceTypeEvent = type => {
  switch (type) {
    case 'priceLimited':
      return EventTypeApply.VLimited;
    case 'priceMonth':
      return EventTypeApply.VMonth;
    case 'priceDay':
      return EventTypeApply.VDay;
    case 'price':
      return EventTypeApply.VLive;
    default:
      return EventTypeApply.VLive;
  }
};

export const getLabelPopover = status => {
  switch (status) {
    case VoucherStatus.VApplying:
      return 'Dừng phát';
    case VoucherStatus.VUpcoming:
      return 'Phát hành';
  }
};


export const convertFieldTicket = (type) => {
  switch (type) {
    case EventTypeApply.VLive:
      return "price";
    case EventTypeApply.VDay:
      return "priceDay";
    case EventTypeApply.VMonth:
      return "priceMonth";
    case EventTypeApply.VLimited:
      return "priceLimited";
    default:
      return "undefined";
  }
}


// export const convertStatusVoucher = (status) => { 
//   switch (status) {
//     case VoucherStatus.VApplying:
//       return "Đang diễn ra";
//     case VoucherStatus.VExpired:
//       return "Hết hạn";
//     case VoucherStatus.VUpcoming:
//       return "Sắp diễn ra";
  
//   }
// }

export const convertVoucherStatus = (status) => { 
  switch (status) { 
      case VerifyVoucherStatus.VAccept:
          return "Đã duyệt";
      case VerifyVoucherStatus.VPending:
          return "Đang chờ duyệt";
      case VerifyVoucherStatus.VReject:
          return "Đã từ chối";
  }
}