import { Col, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import ButtonBack from 'src/components/ButtonBack';
import CommonTable from 'src/components/CommonTable';
import { getLableStatusOrder, getTicketTypeLabel } from 'src/utils/common';
import { customFormatPrice } from 'src/utils/price';
import '../ListTransactions/styles.less';
import useDetailOrder from '../hooks/useDetailOrder';

const DetailOrder = () => {
  const { detailOrder } = useDetailOrder();

  console.log('detailOrder', detailOrder);
  const currentPage = 1;
  const columnInfo = [
    {
      title: 'STT',
      dataIndex: 'index',
      align: 'center',
      render: (text, record, index) => {
        return <Typography.Text>{(currentPage - 1) * 10 + index + 1}</Typography.Text>;
      }
    },
    {
      title: 'Sự kiện',
      dataIndex: 'event',
      align: 'center',
      render(text, record) {
        return <Typography.Text className="text-left">{text?.name}</Typography.Text>;
      }
    },
    {
      title: 'Loại vé',
      dataIndex: 'type',
      align: 'center',
      render(text, record) {
        return <Typography.Text>{getTicketTypeLabel(text).label}</Typography.Text>;
      }
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      align: 'center'
    },

    {
      title: 'Đơn giá',
      dataIndex: 'price',
      align: 'center'
    }
  ];

  return (
    <>
      <div className="">
        {' '}
        <Col span={24}>
          <div className="transaction-table">
            <div className="transaction-table-container">
              <div className="transaction-table-header">
                <div className="text-2xl font-semibold">Chi tiết giao dịch</div>
              </div>
              <div className="mt-10 transaction-table-content" key={'detailOrder'}>
                <div className="flex flex-col gap-3">
                  <div className="grid grid-cols-2">
                    <div className="flex gap-1 items-baseline">
                      <div className="font-semibold">Mã giao dịch: </div>
                      <div className="">{detailOrder?.code}</div>
                    </div>
                    <div className="flex gap-1 items-baseline">
                      <div className="font-semibold">Tổng giá trị: </div>
                      <div className="font-bold">{customFormatPrice(detailOrder?.amount)}</div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div className="flex gap-1 items-baseline">
                      <div className="font-semibold">Tài khoản giao dịch: </div>
                      <div className="">{detailOrder?.userId?.fullName}</div>
                    </div>

                    <div className="flex gap-1 items-baseline">
                      <div className="font-semibold">Cổng giao dịch: </div>
                      <div className="font-semibold text-red-600 uppercase">
                        {detailOrder?.transaction?.gateway}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="flex gap-1 items-baseline">
                      <div className="font-semibold">Thời gian giao dịch: </div>
                      <div className="">
                        {dayjs(detailOrder?.createdAt).format(' HH:mm:ss DD/MM/YYYY')}
                      </div>
                    </div>

                    <div className="flex gap-1 items-baseline">
                      <div className="font-semibold">Trạng thái: </div>
                      <div className="">{getLableStatusOrder(detailOrder?.status)}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <CommonTable
                  columns={columnInfo}
                  dataSource={detailOrder?.items}
                  total={detailOrder?.items?.length}
                  itemName={'title-giao-dich'}
                  pagination={false}
                />
              </div>
              <div className="mt-10 transaction-table-content flex justify-end" key={'detailOrder'}>
                <div className="flex flex-col w-1/2">
                  <div className="flex gap-3">
                    <div className="font-semibold">Tổng: </div>
                    <div className="font-bold">{customFormatPrice(detailOrder?.amount)}</div>
                  </div>
                  <div className="flex gap-3">
                    <div className="font-semibold">Mã giảm giá: </div>
                    <div className="font-bold">{'Chưa có'}</div>
                  </div>
                  <div className="flex gap-3">
                    <div className="font-semibold">Tổng giá trị thanh toán: </div>
                    <div className="font-bold">{customFormatPrice(detailOrder?.amount)}</div>
                  </div>
                </div>
              </div>
              <ButtonBack />
            </div>
          </div>
        </Col>
      </div>
    </>
  );
};

export default DetailOrder;
