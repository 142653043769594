import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';

const Wrapper = styled.div`
  textarea {
    display: block;
    width: 100%;
    box-shadow: rgb(223 223 223) 0 0 3px 3px inset;
    padding: 10px;
    font-weight: 300;

    height: 170px;
  }
`;

export const TextArea = ({ options, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);

  return (
    <Wrapper className={props?.wrapperClassName}>
      <textarea
        className={props?.className}
        value={field.value}
        {...props}
        onChange={event => {
          const { value } = event.target;
          setFieldTouched(field.name, true);
          setFieldValue(field.name, value);
        }}
      />
    </Wrapper>
  );
};

export default TextArea;
